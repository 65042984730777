_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u0644\u0627 \u062a\u062a\u0648\u0641\u0631 \u0646\u0638\u0631\u0629 \u0639\u0627\u0645\u0629. \u0627\u0646\u0642\u0631 \u0641\u0648\u0642 \u0627\u0644\u0633\u062c\u0644 \u0644\u0639\u0631\u0636 \u0627\u0644\u0645\u0632\u064a\u062f \u0645\u0646 \u0627\u0644\u062a\u0641\u0627\u0635\u064a\u0644",
"crm.appointments.status.markcompleted1": "\u062a\u0645\u064a\u064a\u0632 \u0643\u0645\u0643\u062a\u0645\u0644",
"crm.mass.actions.all.selected": "\u062a\u0645 \u062a\u062d\u062f\u064a\u062f <span class=\"dIB vaM\">\u0643\u0644\u00a0</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} \u0641\u064a \u0637\u0631\u064a\u0642\u0629 \u0627\u0644\u0639\u0631\u0636 \u0647\u0630\u0647.</span>",
"crm.module.selectall": "\u062a\u062d\u062f\u064a\u062f \u0643\u0644 {0} \u0641\u064a \u0647\u0630\u0627 \u0627\u0644\u0639\u0631\u0636",
"records": "\u0627\u0644\u0633\u062c\u0644\u0627\u062a",
"crm.record.not.accessible": "\u062a\u0633\u062c\u064a\u0644",
"crm.view.calls.recording.err.msg": "\u0644\u0627 \u064a\u062d\u062a\u0648\u064a \u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u0635\u0648\u062a \u0639\u0644\u0649 \u0627\u0631\u062a\u0628\u0627\u0637 URL \u0644\u0635\u0648\u062a \u0642\u0627\u0628\u0644 \u0644\u0644\u062a\u0634\u063a\u064a\u0644.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} \u0645\u062d\u062f\u062f.</span>",
"totalrecords": "\u0627\u0644\u0633\u062c\u0644\u0627\u062a \u0627\u0644\u0643\u0644\u064a\u0629",
"crm.listview.options.text.clip": "\u0646\u0635 \u0627\u0644\u0642\u0635\u0627\u0635\u0629",
"crm.listview.options.text.wrap": "\u0627\u0644\u062a\u0641\u0627\u0641 \u0627\u0644\u0646\u0635",
"crm.listview.options.reset.width": "\u0625\u0639\u0627\u062f\u0629 \u062a\u0639\u064a\u064a\u0646 \u062d\u062c\u0645 \u0627\u0644\u0639\u0645\u0648\u062f",
"crm.listview.options.manage": "\u0625\u062f\u0627\u0631\u0629 \u0623\u0639\u0645\u062f\u0629",
"crm.listview.customview.locked": "\u0644\u0627 \u064a\u0645\u0643\u0646 \u062a\u062e\u0635\u064a\u0635 \u0627\u0644\u0623\u0639\u0645\u062f\u0629 \u0644\u0623\u0646 \u0637\u0631\u064a\u0642\u0629 \u0627\u0644\u0639\u0631\u0636 \u0647\u0630\u0647 \u0645\u0624\u0645\u0651\u0646\u0629",
"crm.api.apidashboard.SortBy": "\u0641\u0631\u0632 \u062d\u0633\u0628",
"crm.privacy.listview.consent.locked": "\u062a\u0645 \u0642\u0641\u0644 \u0627\u0644\u0633\u062c\u0644 \u0639\u0646 \u0627\u0644\u0645\u0639\u0627\u0644\u062c\u0629",
"crm.label.reschedule.call": "\u0625\u0639\u0627\u062f\u0629 \u062c\u062f\u0648\u0644\u0629 {0}",
"crm.button.edit": "\u062a\u062d\u0631\u064a\u0631",
"Delete": "\u062d\u0630\u0641",
"crm.appointments.status.markcancel": "\u0625\u0644\u063a\u0627\u0621 {0}",
"crm.appointments.status.markreschedule": "\u0625\u0639\u0627\u062f\u0629 \u062c\u062f\u0648\u0644\u0629 {0}",
"crm.label.cancel.call": "\u0625\u0644\u063a\u0627\u0621 {0}",
"crm.label.mark.completed": "\u062a\u0645\u064a\u064a\u0632 \u0643\u0645\u0643\u062a\u0645\u0644",
"crm.listview.customview.locked": "\u0644\u0627 \u064a\u0645\u0643\u0646 \u062a\u062e\u0635\u064a\u0635 \u0627\u0644\u0623\u0639\u0645\u062f\u0629 \u0644\u0623\u0646 \u0637\u0631\u064a\u0642\u0629 \u0627\u0644\u0639\u0631\u0636 \u0647\u0630\u0647 \u0645\u0624\u0645\u0651\u0646\u0629",
"pf.deleted.state": "\u0627\u0644\u062d\u0627\u0644\u0629 \u0627\u0644\u0645\u062d\u0630\u0648\u0641\u0629",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "\u0627\u0644\u0645\u0632\u0627\u0645\u0646\u0629 \u0645\u0639 {0}",
"crm.schedules.prefix.in": "\u0641\u064a",
"crm.record.record.locked.other": "\u0627\u0644\u0633\u062c\u0644 \u0645\u0642\u0641\u0644 \u0623\u0645\u0627\u0645 \u0645\u0633\u062a\u062e\u062f\u0645\u064a \u0627\u0644\u0645\u0644\u0641 \u0627\u0644\u062a\u0639\u0631\u064a\u0641\u064a \u0627\u0644\u0622\u062e\u0631\u064a\u0646.",
"crm.record.lock.record.locked": "\u062a\u0645 \u0642\u0641\u0644 \u0627\u0644\u0633\u062c\u0644.",
"crm.approvalProcess.label.waitingForApproval": "\u0641\u064a \u0627\u0646\u062a\u0638\u0627\u0631 \u0627\u0644\u0645\u0648\u0627\u0641\u0642\u0629",
"crm.reviewprocess.record.review.pending": "\u0642\u064a\u062f \u0627\u0646\u062a\u0638\u0627\u0631 \u0627\u0644\u0645\u0631\u0627\u062c\u0639\u0629",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u062f\u0645\u062c \u0627\u0644\u062a\u0643\u0631\u0627\u0631\u0627\u062a \u0642\u064a\u062f \u0627\u0644\u062a\u0642\u062f\u0645.",
"crm.privacy.listview.consent.locked": "\u062a\u0645 \u0642\u0641\u0644 \u0627\u0644\u0633\u062c\u0644 \u0639\u0646 \u0627\u0644\u0645\u0639\u0627\u0644\u062c\u0629",
"crm.zia.vision.processing": "\u062a\u062a\u062d\u0642\u0642 Zia \u0645\u0646 \u0635\u062d\u0629 \u0627\u0644\u0635\u0648\u0631\u0629 (\u0627\u0644\u0635\u0648\u0631).",
"crm.zia.vision.rejected.msg": "\u062a\u0645 \u0631\u0641\u0636 \u0627\u0644\u062a\u0633\u062c\u064a\u0644 \u0628\u0633\u0628\u0628 \u0641\u0634\u0644 \u0627\u0644\u062a\u062d\u0642\u0642 \u0645\u0646 \u0635\u062d\u0629 \u0627\u0644\u0635\u0648\u0631\u0629.",
"crm.zia.vision.record.failure.msg": "\u064a\u0646\u062a\u0638\u0631 \u0627\u0644\u062a\u0633\u062c\u064a\u0644 \u0645\u0648\u0627\u0641\u0642\u062a\u0643 \u0628\u0633\u0628\u0628 \u0641\u0634\u0644 \u0627\u0644\u062a\u062d\u0642\u0642 \u0645\u0646 \u0635\u062d\u0629 \u0627\u0644\u0635\u0648\u0631\u0629.",
"crm.alert.max.cvcols.reached": "\u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f {0} \u0623\u0639\u0645\u062f\u0629 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",
"crm.label.small.tags": "\u0627\u0644\u0639\u0644\u0627\u0645\u0627\u062a",
"crm.label.edit.module": "\u062a\u062d\u0631\u064a\u0631 {0}",
"crm.social.integ.no.result": "\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0646\u062a\u0627\u0626\u062c",
"custommodule.crmfield.fl.name": "\u0627\u0633\u0645 {0}",
"Appointment": "\u0627\u0644\u0645\u0648\u0639\u062f",
"crm.button.create.call": "\u0625\u0646\u0634\u0627\u0621 \u0645\u0643\u0627\u0644\u0645\u0629",
"crm.module.create": "\u0625\u0646\u0634\u0627\u0621 {0}",
"crm.customview.activty.badge": "\u0634\u0627\u0631\u0629 \u0627\u0644\u0646\u0634\u0627\u0637",
"crm.button.callnow": "\u0627\u062a\u0635\u0644 \u0627\u0644\u0622\u0646",
"crm.manualcalllist.complete.call": "\u0633\u062c\u0651\u0644 \u0645\u0643\u0627\u0644\u0645\u0629",
"crm.label.schedule.call": "\u062c\u062f\u0648\u0644\u0629 \u0645\u0643\u0627\u0644\u0645\u0629",
"crm.filter.label.replied":"\u062a\u0645 \u0627\u0644\u0631\u062f",
"crm.field.label.user.lookup": "\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",
"crm.translation.refresh.cases": "\u062a\u0639\u0630\u0631 \u0627\u0633\u062a\u0643\u0645\u0627\u0644 \u0627\u0644\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0630\u064a \u062a\u062d\u0627\u0648\u0644 \u062a\u0646\u0641\u064a\u0630\u0647. \u064a\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062b \u0627\u0644\u0635\u0641\u062d\u0629 \u0648\u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629.",
"crm.project.sync.notes.info": "\u0633\u0648\u0641 \u062a\u0638\u0647\u0631 \u062a\u0639\u0644\u064a\u0642\u0627\u062a \u0645\u0647\u0627\u0645 \u0627\u0644\u0645\u0634\u0631\u0648\u0639\u0627\u062a \u0627\u0644\u0645\u0642\u062a\u0631\u0646\u0629 \u0641\u0642\u0637",
"crux.note.content.limit.warning.msg":"\u064a\u064f\u0633\u0645\u062d \u0628\u062d\u0641\u0638 {0} \u0645\u0646 \u0627\u0644\u0623\u062d\u0631\u0641 \u0641\u0642\u0637 \u0641\u064a \u0645\u062d\u062a\u0648\u0649 \u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a",
"bytes": "\u0628\u0627\u064a\u062a",
"crux.note.formatting.options":"\u062e\u064a\u0627\u0631\u0627\u062a \u0627\u0644\u062a\u0646\u0633\u064a\u0642",
"crm.label.required": "\u0645\u0637\u0644\u0648\u0628",
"crux.condition.not.belongs.to":"\u0644\u0627 \u064a\u0646\u062a\u0645\u064a \u0625\u0644\u0649 {0}",
"crm.project.sync.notes.info": "\u0633\u0648\u0641 \u062a\u0638\u0647\u0631 \u062a\u0639\u0644\u064a\u0642\u0627\u062a \u0645\u0647\u0627\u0645 \u0627\u0644\u0645\u0634\u0631\u0648\u0639\u0627\u062a \u0627\u0644\u0645\u0642\u062a\u0631\u0646\u0629 \u0641\u0642\u0637",
"crm.sf.permission.no.edit": "\u0644\u064a\u0633 \u0644\u062f\u064a\u0643 \u0625\u0630\u0646 \u0644\u062a\u062d\u062f\u064a\u062b \u0625\u062f\u062e\u0627\u0644\u0627\u062a.",
"crm.sf.permission.no.create": "\u0644\u064a\u0633 \u0644\u062f\u064a\u0643 \u0625\u0630\u0646 \u0644\u0625\u0636\u0627\u0641\u0629 \u0625\u062f\u062e\u0627\u0644\u0627\u062a.",
"crm.sf.permission.no.delete": "\u0644\u064a\u0633 \u0644\u062f\u064a\u0643 \u0625\u0630\u0646 \u0644\u062d\u0630\u0641 \u0625\u062f\u062e\u0627\u0644\u0627\u062a.",
"crux.note.this.note":"\u0647\u0630\u0647 \u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0629",
"KB": "\u0643\u064a\u0644\u0648 \u0628\u0627\u064a\u062a",
"MB": "\u0645\u064a\u062c\u0627 \u0628\u0627\u064a\u062a",
"current.logged.in.user.role": "\u062f\u0648\u0631 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0642\u064a\u062f \u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u062f\u062e\u0648\u0644",
"Unit Price": "\u0633\u0639\u0631 \u0627\u0644\u0648\u062d\u062f\u0629",
"Qty in Stock": "\u0627\u0644\u0643\u0645\u064a\u0629 \u0627\u0644\u0645\u062a\u0648\u0641\u0631\u0629 \u0628\u0627\u0644\u0645\u062e\u0632\u0646",
"crm.label.Product Code": "\u0631\u0645\u0632 \u0627\u0644\u0645\u0646\u062a\u062c",
"TotalAfterDiscount": "\u0627\u0644\u0625\u062c\u0645\u0627\u0644\u064a \u0628\u0639\u062f \u0627\u0644\u062e\u0635\u0645",
"crm.lookupfilter.entity.errormsg": "\u0644\u0627 \u064a\u0633\u062a\u0648\u0641\u064a {0} \u0627\u0644\u0645\u062d\u062f\u062f \u0645\u0639\u0627\u064a\u064a\u0631 \u0627\u0644\u0628\u062d\u062b.",
"crm.iamexception.maxlen": "\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u0625\u062f\u062e\u0627\u0644 \u0623\u0643\u062b\u0631 \u0645\u0646 {0} \u062d\u0631\u0641\u064b\u0627 \u0644\u0640 {1}",
"crm.record.locking.permission.denied": "\u0644\u0627 \u064a\u0645\u0643\u0646 \u062a\u0646\u0641\u064a\u0630 \u0647\u0630\u0627 \u0627\u0644\u0625\u062c\u0631\u0627\u0621 \u0644\u0623\u0646 \u0627\u0644\u0633\u062c\u0644 \u0645\u0642\u0641\u0644.",
"List Price": "\u0625\u062f\u0631\u0627\u062c \u0627\u0644\u0633\u0639\u0631",
"crm.label.no.more.records": "\u0644\u0627 \u064a\u0648\u062c\u062f \u0627\u0644\u0645\u0632\u064a\u062f \u0645\u0646 \u0627\u0644\u0633\u062c\u0644\u0627\u062a",
"crm.workflow.rule.created.dateAndTime":"{0} \u0641\u064a {1} \u0639\u0646\u062f {2}",
"crux.new.note.msg":"\u0645\u0627 \u0645\u0648\u0636\u0648\u0639 \u0647\u0630\u0647 \u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0629\u061f",
"crm.image.uploaded":"\u062a\u0645 \u062a\u062d\u0645\u064a\u0644 \u0627\u0644\u0635\u0648\u0631\u0629",
"crm.imageupload.drag.drop.here":"\u0627\u0633\u062d\u0628 \u0627\u0644\u0635\u0648\u0631 \u0648\u0623\u0641\u0644\u062a\u0647\u0627 \u0647\u0646\u0627",
"crm.fileupload.drag.drop.here":"\u0627\u0633\u062d\u0628 \u0627\u0644\u0645\u0644\u0641\u0627\u062a \u0648\u0623\u0641\u0644\u062a\u0647\u0627 \u0647\u0646\u0627",
"crm.fileupload.wait.msg":"\u064a\u0631\u062c\u0649 \u0627\u0644\u0627\u0646\u062a\u0638\u0627\u0631 \u062d\u062a\u0649 \u064a\u062a\u0645 \u062a\u062d\u0645\u064a\u0644 \u0627\u0644\u0645\u0644\u0641\u0627\u062a \u0627\u0644\u0645\u062d\u062f\u062f\u0629",
"crm.fileupload.attach.fail":"\u0641\u0634\u0644 \u0627\u0644\u0645\u0631\u0641\u0642",
"crm.fileupload.support.format":"\u062a\u0646\u0633\u064a\u0642 \u0627\u0644\u0645\u0644\u0641\u0627\u062a \u0627\u0644\u0645\u062f\u0639\u0648\u0645 \u0644\u062a\u062f\u0631\u064a\u0628 Zia \u0647\u064a\u061b jpeg \u0648jpg \u0648png \u0648pdf",
"custmr.prtl.user.role": "\u0645\u0633\u062a\u062e\u062f\u0645 \u0627\u0644\u0645\u062f\u062e\u0644",
"crm.subform.empty.alert.header":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u062d\u0641\u0638 \u0627\u0644\u0635\u0641 \u0641\u0627\u0631\u063a\u064b\u0627",
"crm.crud.lookup.module.inaccessible": "\u0644\u064a\u0633 \u0644\u062f\u064a\u0643 \u0625\u0630\u0646 \u0644\u0644\u0648\u0635\u0648\u0644 \u0625\u0644\u0649 \u0648\u062d\u062f\u0629 \u0627\u0644\u0628\u062d\u062b \u0647\u0630\u0647",
"crm.crud.lookup.inaccessible.record": "\u0627\u0644\u0633\u062c\u0644 \u063a\u064a\u0631 \u0645\u0648\u062c\u0648\u062f \u0623\u0648 \u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u0627\u0644\u0648\u0635\u0648\u0644 \u0625\u0644\u064a\u0647",
"crm.custombutton.valid.weburl.check": "\u0627\u0644\u0631\u062c\u0627\u0621 \u0625\u062f\u062e\u0627\u0644 \u0642\u064a\u0645\u0629.",
"crm.label.subform.goto.top": "\u0627\u0646\u062a\u0642\u0644 \u0625\u0644\u0649 \u0623\u0639\u0644\u0649 \u0627\u0644\u0642\u0627\u0626\u0645\u0629",
"crm.label.subform.row.show.all": "\u0625\u0638\u0647\u0627\u0631 \u0627\u0644\u0643\u0644 ({0})",
"cpq.pr.nodiscount": "\u0644\u0627 \u064a\u0648\u062c\u062f \u062e\u0635\u0645 \u0645\u0642\u062a\u0631\u0646",
"crm.inventory.lineitem.no.pricebook.new": "\u0644\u0627 \u062a\u0648\u062c\u062f {0} \u0645\u0642\u062a\u0631\u0646\u0629.",
"crm.label.subform.allowedlimit": "\u062a\u0645 \u0627\u0644\u0648\u0635\u0648\u0644 \u0625\u0644\u0649 \u062d\u062f {0} \u0645\u0646 \u0627\u0644\u0635\u0641\u0648\u0641 \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0628\u0647",
"crm.reports.integ.okay": "\u0644\u0627 \u0628\u0623\u0633",
"crm.crud.subform.deleted.record": "\u062a\u0645 \u062d\u0630\u0641 \u0635\u0641 (\u0635\u0641\u0648\u0641) \u0627\u0644\u0646\u0645\u0648\u0630\u062c \u0627\u0644\u0641\u0631\u0639\u064a \u0627\u0644\u0645\u062d\u062f\u062b \u0628\u0627\u0644\u0641\u0639\u0644. \u064a\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062b \u0627\u0644\u0635\u0641\u062d\u0629 \u0648\u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629.",
"crux.criteria.fieldlabel.valid.check":"\u0627\u0644\u0631\u062c\u0627\u0621 \u0625\u062f\u062e\u0627\u0644 \u062a\u0633\u0645\u064a\u0629 \u062d\u0642\u0644 \u0635\u0627\u0644\u062d\u0629",
"crm.subform.delete.confirm.header":"حذف صف النموذج الفرعي",
"crm.subform.delete.confirm.msg": "هل أنت متأكد من رغبتك في حذف الصف المحدد في {0}؟",
"crm.subform.delete.tooltip.msg" :"لا يمكن حذف الصف. مطلوب صف من إدخال واحد على الأقل لـ {0}.",
	"crm.condition.belongs.to":"\u064a\u0646\u062a\u0645\u064a \u0625\u0644\u0649 {0}",//no i18n
"crm.condition.not.belongs.to":"\u0644\u0627 \u064a\u0646\u062a\u0645\u064a \u0625\u0644\u0649 {0}",//no i18n
"crm.orchestration.error.selectvalue": "\u064a\u064f\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062f \u0642\u064a\u0645\u0629",
	"crm.attach.upload.image":"\u0625\u0631\u0641\u0627\u0642 \u0635\u0648\u0631",//no i18n
"crm.general.select.photo":"\u062a\u062d\u062f\u064a\u062f \u0635\u0648\u0631\u0629",//no i18n
"crm.imageupload.wait.msg":"\u064a\u0631\u062c\u0649 \u0627\u0644\u0627\u0646\u062a\u0638\u0627\u0631 \u062d\u062a\u0649 \u062a\u062d\u0645\u064a\u0644 \u0627\u0644\u0635\u0648\u0631 \u0627\u0644\u0645\u062d\u062f\u062f\u0629.",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u0633\u062d\u0628 \u0648\u0625\u0641\u0644\u0627\u062a \u0627\u0644\u0635\u0648\u0631.",//no i18n
"crm.image.filepicker.desc.browse":"\u0627\u0646\u0642\u0631 \u0644\u0627\u0633\u062a\u0639\u0631\u0627\u0636 \u0627\u0644\u0635\u0648\u0631...",//no i18n
"crm.attach.upload.userinfo":"\u0627\u0644\u062d\u062c\u0645 \u0627\u0644\u0625\u062c\u0645\u0627\u0644\u064a \u0645\u0642\u062a\u0635\u0631 \u0639\u0644\u0649 <span class",//no i18n
"crm.image.supported.formats":"\u0627\u0644\u062a\u0646\u0633\u064a\u0642\u0627\u062a \u0627\u0644\u0645\u062f\u0639\u0648\u0645\u0629: JPEG \u0648PNG \u0648GIF \u0648BMP.",//no i18n
"crm.imageupload.allowed.field.length":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u0645\u064a\u0644 \u0633\u0648\u0649 {0} \u0635\u0648\u0631 \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",//no i18n
"crm.general.crop.and.set":"\u0627\u0644\u0627\u0642\u062a\u0635\u0627\u0635 \u0648\u0627\u0644\u062a\u0639\u064a\u064a\u0646",//no i18n
"crm.attach.option.label":"\u0625\u0631\u0641\u0627\u0642",//no i18n
"crm.image.crop.and.rotate":"\u0627\u0644\u0627\u0642\u062a\u0635\u0627\u0635 \u0648\u0627\u0644\u062a\u062f\u0648\u064a\u0631",//no i18n
"crm.image.resolution":"\u0627\u0644\u062f\u0642\u0629",//no i18n
"crm.attachment.size":"\u0627\u0644\u062d\u062c\u0645",//no i18n
"crm.label.close":"\u0625\u063a\u0644\u0627\u0642",//no i18n
"crm.label.previous":"\u0627\u0644\u0633\u0627\u0628\u0642",//no i18n
"crm.label.next":"\u0627\u0644\u062a\u0627\u0644\u064a",//no i18n
	"voc.gc.configure":"\u062a\u0643\u0648\u064a\u0646",//no i18n
"Edit":"\u062a\u062d\u0631\u064a\u0631",//no i18n.
"crm.subform.record.create.maxrow.limit": "\u0627\u0644\u062d\u062f \u0627\u0644\u0623\u0642\u0635\u0649 \u0642\u062f\u0631\u0647 {0} \u0633\u062c\u0644\u064b\u0627 \u0645\u0633\u0645\u0648\u062d\u064b\u0627 \u0628\u0647 \u0641\u064a {1}",
"crm.label.subform.addrows": "\u0625\u0636\u0627\u0641\u0629 \u0635\u0641",
"crm.tax.association.check": "\u0644\u0627 \u062a\u0648\u062c\u062f \u0636\u0631\u064a\u0628\u0629 \u0645\u0642\u062a\u0631\u0646\u0629 \u0628\u0647\u0630\u0627 \u0627\u0644\u0645\u0646\u062a\u062c.",
	"crm.gallery.inventory.template.discount": "\u062e\u0635\u0645",
	"crm.inventory.discount.scheme.range": "\u0627\u0644\u0646\u0637\u0627\u0642",
	"Description": "\u0627\u0644\u0648\u0635\u0641",
	"crm.inventory.lineitem.no.pricebook": "\u0644\u0645 \u064a\u062a\u0645 \u0625\u0642\u0631\u0627\u0646 \u0623\u064a \u0643\u062a\u064a\u0628\u0627\u062a \u0623\u0633\u0639\u0627\u0631.",
	"crm.recordImage.previewimage":"\u0635\u0648\u0631\u0629 \u0645\u0639\u0627\u064a\u0646\u0629",//no i18n
"crm.FileuploadField.addNewImage":"\u062a\u062d\u0645\u064a\u0644 \u0635\u0648\u0631\u0629",//no i18n
"crm.fileuploader.removefile":"\u0625\u0632\u0627\u0644\u0629",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "\u064a\u064f\u0631\u062c\u0649 \u062a\u0648\u0641\u064a\u0631 \u0642\u064a\u0645\u0629 \u0645\u0637\u0627\u0628\u0642\u0629 \u0644\u0640 {0}",
	"crm.label.field":"\u0627\u0644\u062d\u0642\u0644",//no i18n
"crm.label.value":"\u0627\u0644\u0642\u064a\u0645\u0629",//no i18n
	"crm.button.clone":"\u0627\u0633\u062a\u0646\u0633\u0627\u062e",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u062a\u0643\u0648\u0646 \u0642\u064a\u0645\u0629 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631 \u0623\u0643\u062b\u0631 \u0645\u0646 {0}",//no i18n
"crm.mb.field.common.splc":"\u063a\u064a\u0631 \u0645\u0633\u0645\u0648\u062d \u0628\u0627\u0633\u062a\u062e\u062f\u0627\u0645 \u0627\u0644\u0623\u062d\u0631\u0641 \u0627\u0644\u062e\u0627\u0635\u0629. \u0623\u062f\u062e\u0644 \u0642\u064a\u0645\u0629 \u0635\u0627\u0644\u062d\u0629.",//no i18n
	"crm.view.attachment.download":"\u062a\u0646\u0632\u064a\u0644",//no i18n
"crm.label.view":"\u0639\u0631\u0636",//no i18n
		"crm.label.field.plural":"\u062d\u0642\u0648\u0644", // NO I18N
	"crm.label.in.minutes":"{0} (\u0628\u0627\u0644\u062f\u0642\u0627\u0626\u0642)",//no i18n
	"crm.territory.addterritory":"\u0625\u0636\u0627\u0641\u0629 \u0645\u0646\u0637\u0642\u0629",//no i18n
"crm.title.edit.territory":"\u062a\u062d\u0631\u064a\u0631 \u0627\u0644\u0645\u0646\u0637\u0642\u0629",//no i18n
"crm.territory.title.assign.territories":"\u062a\u0639\u064a\u064a\u0646 \u0627\u0644\u0645\u0646\u0627\u0637\u0642",//no i18n
	"crm.security.roles.list":"\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0623\u062f\u0648\u0627\u0631",//no i18n
"crm.security.roles.lookup.info":"\u064a\u064f\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062f \u062f\u0648\u0631 \u0648\u0627\u062d\u062f \u0645\u0646 \u0627\u0644\u0642\u0627\u0626\u0645\u0629.",//no i18n
	"crm.label.context.help":"\u0627\u0644\u0645\u0633\u0627\u0639\u062f\u0629",//no i18n
"crm.label.from":"\u0627\u0644\u0645\u0631\u0633\u0644",//no i18n
"crm.label.to":"\u0625\u0644\u0649",//no i18n
"workflow.option.webhookFailure.fromDate":"\u062a\u0627\u0631\u064a\u062e \u0627\u0644\u0628\u062f\u0621",//no i18n
"workflow.option.webhookFailure.toDate":"\u062a\u0627\u0631\u064a\u062e \u0627\u0644\u0627\u0646\u062a\u0647\u0627\u0621",//no i18n
"crm.custom.field.less.than.equalto":"\u064a\u062c\u0628 \u0623\u0646 \u064a\u0643\u0648\u0646 {0} \u0623\u0642\u0644 \u0645\u0646 \u0623\u0648 \u064a\u0633\u0627\u0648\u064a {1}.",//no i18n
"crm.template.listview.search.no.results":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0646\u062a\u0627\u0626\u062c",//No I18n
"crm.wf.usage.date.criteria.error.msg":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u064a\u0643\u0648\u0646 \u062a\u0627\u0631\u064a\u062e \u201d\u0627\u0644\u0628\u062f\u0621\u201c \u0623\u0643\u0628\u0631 \u0645\u0646 \u062a\u0627\u0631\u064a\u062e \u201d\u0627\u0644\u0627\u0646\u062a\u0647\u0627\u0621\u201c.",//no i18n
"crm.label.tag.new":"\u0639\u0644\u0627\u0645\u0629 \u062c\u062f\u064a\u062f\u0629",//No I18n
"crm.label.enter.tag":"\u0623\u062f\u062e\u0644 \u0627\u0644\u0639\u0644\u0627\u0645\u0627\u062a",//No I18n
"Administrator":"\u0627\u0644\u0645\u0633\u0624\u0648\u0644",//No I18n
"Standard":"Standard",//No I18n
"crux.comboBox.max.limit":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0623\u0643\u062b\u0631 \u0645\u0646 {0} {1}.",//NO I18n
"crm.button.add":"\u0625\u0636\u0627\u0641\u0629",//NO I18n
"crm.label.users":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645\u0648\u0646", //NO I18n
"crm.workflow.alert.roles":"\u0627\u0644\u0623\u062f\u0648\u0627\u0631", //NO I18n
"crm.security.groups":"\u0627\u0644\u0645\u062c\u0645\u0648\u0639\u0627\u062a", //NO I18n
"crm.label.available" : "\u0645\u062a\u0627\u062d", //NO I18n
"crm.label.assign.manually" : "\u062a\u0639\u064a\u064a\u0646", //NO I18n
"crm.globalsearch.option.all": "\u0627\u0644\u0643\u0644", //NO I18n
"webform.status.Active":"\u0646\u0634\u0637", //NO I18n
"Inactive":"\u063a\u064a\u0631 \u0646\u0634\u0637", //NO I18n
"Confirmed":"\u062a\u0645 \u0627\u0644\u062a\u0623\u0643\u064a\u062f", //NO I18n
"DeletedUser":"\u0645\u0633\u062a\u062e\u062f\u0645\u0648\u0646 \u0645\u062d\u0630\u0648\u0641\u0648\u0646", //NO I18n
"crm.user.component.unconfirmed":"\u063a\u064a\u0631 \u0645\u0624\u0643\u062f\u0629",//no i18n
"crm.feed.group.admin":"\u0627\u0644\u0645\u0633\u0624\u0648\u0644", //NO I18n
"crm.ln.lable.current":"\u0627\u0644\u062d\u0627\u0644\u064a", //NO I18n
"crm.security.group.users.empty":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0623\u064a \u0645\u0633\u062a\u062e\u062f\u0645\u064a\u0646.",//no i18n
"crm.label.picklist.none":"\u0628\u062f\u0648\u0646",//no i18n
"crm.label.selected":"\u062a\u0645 \u0627\u0644\u062a\u062d\u062f\u064a\u062f",//no i18n
"crm.label.notSelected":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u062a\u062d\u062f\u064a\u062f.",//no i18n
"AM":"\u0635\u0628\u0627\u062d\u064b\u0627",//no i18n
"Call":"\u0645\u0643\u0627\u0644\u0645\u0629",//no i18n
"crm.phoneNo.Link.Title":"\u0627\u0644\u0627\u062a\u0635\u0627\u0644 \u0628\u0627\u0633\u062a\u062e\u062f\u0627\u0645 Skype",//no i18n
"crm.button.cancel":"\u0625\u0644\u063a\u0627\u0621",//no i18n
"crm.button.save":"\u062d\u0641\u0638",//no i18n
"crm.usrpop.non.selected":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645\u0648\u0646 \u0627\u0644\u0645\u062d\u062f\u062f\u0648\u0646",//no i18n
"crm.zti.label.user":"\u0627\u0633\u0645 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.auditlog.user":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"cob.role":"\u0627\u0644\u062f\u0648\u0631",//no i18n
"zoho.email":"\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"Profile":"\u0645\u0644\u0641 \u0627\u0644\u062a\u0639\u0631\u064a\u0641",//no i18n
"crm.label.no.options.found":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u062e\u064a\u0627\u0631\u0627\u062a.",//no i18n
"crm.globalsearch.search.title":"\u0628\u062d\u062b",//no i18n
"None":"\u0628\u062f\u0648\u0646",//no i18n
"crm.label.criteria.pattern":"\u0646\u0645\u0637 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631",//no i18n
"crm.label.edit.criteria.pattern":"\u062a\u062d\u0631\u064a\u0631 \u0627\u0644\u0646\u0645\u0637",//no i18n
"criteria.error.alert.brackets.count.mismatch":"\u0623\u0642\u0648\u0627\u0633 \u0627\u0644\u0646\u0645\u0637 \u063a\u064a\u0631 \u0645\u062a\u0637\u0627\u0628\u0642\u0629.",//no i18n
"criteria.error.alert.brackets.invalid":"\u0623\u0642\u0648\u0627\u0633 \u063a\u064a\u0631 \u0635\u062d\u064a\u062d\u0629 \u062a\u062d\u064a\u0637 \u0628\u0639\u0627\u0645\u0644 (\u0639\u0648\u0627\u0645\u0644) \u062a\u0634\u063a\u064a\u0644 \u0627\u0644\u0634\u0631\u0637.",//no i18n
"crm.criteria.number.notmatch.check":"\u064a\u064f\u0631\u062c\u0649 \u0627\u0644\u062a\u062d\u0642\u0642 \u0645\u0646 \u0627\u0644\u0646\u0645\u0637 \u0639\u0644\u0649 {0}.",//no i18n
"criteria.error.alert.other.params":"\u0645\u062d\u062a\u0648\u0649 \u063a\u064a\u0631 \u0635\u062d\u064a\u062d \u0641\u064a \u0647\u0630\u0627 \u0627\u0644\u0646\u0645\u0637.",//no i18n
"crm.label.search.for.users":"\u0627\u0644\u0628\u062d\u062b \u0639\u0646 \u0645\u0633\u062a\u062e\u062f\u0645\u064a\u0646",//no i18n
"criteria.error.alert.andor.rowcount.mismatch":"\u0646\u0645\u0637 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631 \u0644\u0627 \u064a\u062a\u0648\u0627\u0641\u0642 \u0645\u0639 \u0627\u0644\u0634\u0631\u0648\u0637 \u0627\u0644\u062a\u064a \u0627\u062e\u062a\u0631\u062a\u0647\u0627.",//no i18n
"criteria.error.alert.critnum.rowcount.mismatch":"\u0646\u0645\u0637 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631 \u0644\u0627 \u064a\u062a\u0648\u0627\u0641\u0642 \u0645\u0639 \u0627\u0644\u0634\u0631\u0648\u0637 \u0627\u0644\u062a\u064a \u0627\u062e\u062a\u0631\u062a\u0647\u0627.",//no i18n
"and":"\u0648",//no i18n
"or":"\u0623\u0648",//no i18n
"crm.label.or":"\u0623\u0648",//no i18n
"crm.label.and":"\u0648",//no i18n
"crm.criteria.fieldlabel.valid.check":"\u064a\u064f\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 \u062a\u0633\u0645\u064a\u0629 \u062d\u0642\u0644 \u0635\u0627\u0644\u062d \u0641\u064a \u0635\u0641 {0}.",//no i18n
"crm.criteria.condition.valid.check":"\u064a\u064f\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 \u0634\u0631\u0637 \u0635\u0627\u0644\u062d \u0644\u0640 {0}.",//no i18n
"crm.field.valid.check":"\u064a\u064f\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 {0} \u0635\u0627\u0644\u062d.",//no i18n
"crm.custom.field.less.than.to":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u064a\u0643\u0648\u0646 \u0646\u0637\u0627\u0642 <i>\u0627\u0644\u0628\u062f\u0621</i> \u0623\u0643\u0628\u0631 \u0645\u0646 \u0646\u0637\u0627\u0642 <i>\u0627\u0644\u0627\u0646\u062a\u0647\u0627\u0621</i>.",//no i18n
"crm.alert.label.savepattern":"\u0627\u062d\u0641\u0638 \u0627\u0644\u0646\u0645\u0637 \u0642\u0628\u0644 \u062a\u063a\u064a\u064a\u0631 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631.",//no i18n
"crm.criteria.max.rowcnt.exceeds":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u0625\u0636\u0627\u0641\u0629 \u0645\u0639\u0627\u064a\u064a\u0631 \u0625\u0636\u0627\u0641\u064a\u0629.",//no i18n
"is":"\u064a\u0643\u0648\u0646",//no i18n
"isn\'t":"\u0644\u064a\u0633",//no i18n
"contains":"\u064a\u062d\u062a\u0648\u064a \u0639\u0644\u0649",//no i18n
"between":"\u0628\u064a\u0646",//no i18n
"Today":"\u0627\u0644\u064a\u0648\u0645",//no i18n
"Tommorow":"\u063a\u062f\u064b\u0627",//no i18n
"Yesterday":"\u0623\u0645\u0633",//no i18n
"Scheduled":"\u0645\u062c\u062f\u0648\u0644",//no i18n
"Overdue":"\u0645\u062a\u0623\u062e\u0631",//no i18n
"Cancelled":"\u062a\u0645 \u0627\u0644\u0625\u0644\u063a\u0627\u0621",//no i18n
"Received":"\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"Missed":"\u0627\u0644\u0641\u0627\u0626\u062a\u0629",//no i18n
"crm.alert.character.not.allowed":"{0} \u063a\u064a\u0631 \u0645\u0633\u0645\u0648\u062d",//no i18n
"crm.condition.in.last":"\u0641\u064a \u0627\u0644\u0623\u062e\u064a\u0631",//no i18n
"crm.zinvoice.dueIn":"\u0645\u0633\u062a\u062d\u0642 \u0641\u064a",//no i18n
"on":"\u062a\u0634\u063a\u064a\u0644",//no i18n
"before":"\u0642\u0628\u0644",//no i18n
"crm.label.general.small.after":"\u0628\u0639\u062f",//no i18n
"crm.thisweek":"\u0647\u0630\u0627 \u0627\u0644\u0623\u0633\u0628\u0648\u0639",//no i18n
"crm.label.this.month":"\u0647\u0630\u0627 \u0627\u0644\u0634\u0647\u0631",//no i18n
"crm.thisyear":"\u0647\u0630\u0627 \u0627\u0644\u0639\u0627\u0645",//no i18n
"crm.source.user.and.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0648\u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"crm.source.user.or.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0623\u0648 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"crm.label.system2":"\u0646\u0638\u0627\u0645",//no i18n
"crm.source.user.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.source.system.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"crm.condition.till.today":"\u062d\u062a\u0649 \u0627\u0644\u064a\u0648\u0645",//no i18n
"game.month.left":"1 \u0634\u0647\u0631",//no i18n
"game.months.left":"{0} \u0623\u0634\u0647\u0631",//no i18n
"crm.condition.last.30.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 30 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.60.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 60 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.90.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 90 \u0623\u064a\u0627\u0645",//no i18n
"crm.label.filter.typehere":"\u0627\u0643\u062a\u0628 \u0647\u0646\u0627",//no i18n
"crm.filter.is.not":"\u0644\u0627 \u064a\u0643\u0648\u0646",//no i18n
"crm.condition.until.now":"\u062d\u062a\u0649 \u0627\u0644\u0622\u0646",//no i18n
"crm.filter.email.isblocked":"\u0645\u062d\u0638\u0648\u0631",//no i18n
"crm.filter.email.isnotblocked":"\u063a\u064a\u0631 \u0645\u062d\u0638\u0648\u0631",//no i18n
"crm.label.no.results.match":"\u0644\u0627 \u062a\u0648\u062c\u062f \u0646\u062a\u0627\u0626\u062c \u0645\u0637\u0627\u0628\u0642\u0629",//no i18n
"crm.label.select.user":"\u0627\u0646\u0642\u0631 \u0644\u062a\u062d\u062f\u064a\u062f \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645\u064a\u0646.",//no i18n
"current.logged.in.user":"\u0645\u0633\u062a\u062e\u062f\u0645 \u0645\u0633\u062c\u0651\u0644 \u062f\u062e\u0648\u0644\u0647",//no i18n
"current.logged.in.user.definition":"\u0627\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0627\u0644\u0630\u064a \u064a\u0628\u062f\u0623 \u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0633\u062c\u0644.",//no i18n
"crm.security.group":"\u0627\u0644\u0645\u062c\u0645\u0648\u0639\u0629",//no i18n
"crm.security.role":"\u0627\u0644\u062f\u0648\u0631",//no i18n
"Date":"\u0627\u0644\u062a\u0627\u0631\u064a\u062e",//no i18n
"crm.field.valid.decimal.check2":"\u0627\u0644\u0623\u0645\u0627\u0643\u0646 \u0627\u0644\u0639\u0634\u0631\u064a\u0629 \u0644\u062d\u0642\u0644 <i>{0}</i> \u064a\u062c\u0628 \u0623\u0646 \u062a\u0643\u0648\u0646 \u0623\u0642\u0644 \u0645\u0646 \u0623\u0648 \u062a\u0633\u0627\u0648\u064a {1}.",//no i18n
"crm.field.empty.check":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u064a\u0643\u0648\u0646 {0} \u0641\u0627\u0631\u063a\u064b\u0627.",//no i18n
"crm.lead.prediction.convert.high":"\u0645\u0631\u062a\u0641\u0639",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\u0645\u062a\u0648\u0633\u0637",//no i18n
"crm.lead.prediction.convert.low":"\u0645\u0646\u062e\u0641\u0636",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0627\u0644\u062a\u0631\u062c\u064a\u062d",//no i18n
"crm.lead.prediction.tooltip.score":"\u0627\u0644\u062f\u0631\u062c\u0629",//no i18n
"Planned":"\u0645\u062e\u0637\u0637\u0629",//no i18n
"Invited":"\u062a\u0645\u062a \u0627\u0644\u062f\u0639\u0648\u0629",//no i18n
"Sent":"\u0645\u0631\u0633\u0644\u0629",//no i18n
"Received":"\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"Opened":"\u062a\u0645 \u0627\u0644\u0641\u062a\u062d",//no i18n
"Responded":"\u062a\u0645 \u0627\u0644\u0631\u062f",//no i18n
"Bounced":"\u062a\u0645 \u0627\u0644\u0627\u0631\u062a\u062f\u0627\u062f",//no i18n
"Opted\ Out":"\u0627\u0644\u0627\u0646\u0633\u062d\u0627\u0628",//no i18n
"crm.filter.label.with.open":"\u0645\u0639 {0} \u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.without.open":"\u062f\u0648\u0646 \u0641\u062a\u062d {0}",//no i18n
"crm.filter.label.without.any":"\u062f\u0648\u0646 \u0623\u064a {0}",//no i18n
"crm.filter.label.with.module":"\u0645\u0639 {0}",//no i18n
"Overdue":"\u0645\u062a\u0623\u062e\u0631",//no i18n
"crm.filter.label.activity.due":"{0} \u0645\u0633\u062a\u062d\u0642",//no i18n
"crm.filter.label.activity.done":"{0} \u062a\u0627\u0645",//no i18n
"Notes":"\u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a",//no i18n
"crm.filter.label.notes.added":"\u0645\u0644\u0627\u062d\u0638\u0627\u062a \u0645\u0636\u0627\u0641\u0629",//no i18n
"crm.label.filter.email.status":"\u062d\u0627\u0644\u0629 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0627\u0644\u0623\u062e\u064a\u0631\u0629",//no i18n
"crm.label.filter.email.clicked":"\u062a\u0645 \u0627\u0644\u0646\u0642\u0631 \u0639\u0644\u064a\u0647",//no i18n
"crm.label.filter.email.responded":"\u062a\u0645 \u0627\u0644\u0631\u062f", //no i18n
"crm.label.filter.email.info":"\u062a\u0635\u0641\u064a\u0629 \u0627\u0644\u0633\u062c\u0644\u0627\u062a \u0628\u0646\u0627\u0621\u064b \u0639\u0644\u0649 \u0627\u0644\u062d\u0627\u0644\u0629 \u0627\u0644\u0623\u062e\u064a\u0631\u0629 \u0644\u0644\u0631\u0633\u0627\u0626\u0644 \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a\u0629 \u0627\u0644\u0645\u0631\u0633\u0644\u0629/\u0627\u0644\u0645\u0633\u062a\u0644\u0645\u0629 \u0627\u0644\u062e\u0627\u0635\u0629 \u0628\u0643.",//no i18n
"crm.filter.label.sent":"\u0645\u0631\u0633\u0644",//no i18n
"crm.filter.label.not.sent":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0625\u0631\u0633\u0627\u0644",//no i18n
"crm.filter.label.opened":"\u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.not.opened":"\u063a\u064a\u0631 \u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.received":"\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"crm.filter.label.not.received":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"crm.filter.label.bounced":"\u0645\u0631\u062a\u062f",//no i18n
"crm.filter.label.opened.not.replied":"\u062a\u0645 \u0641\u062a\u062d\u0647\u0627 \u0648\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0631\u062f \u0639\u0644\u064a\u0647\u0627", //no i18n
"crm.filter.label.any":"\u0623\u064a \u0645\u0645\u0627 \u0633\u0628\u0642",//no i18n
"crm.zia.config.potential.amount":"\u0645\u0628\u0644\u063a {0}",//no i18n
"Quote\ Stage":"\u0645\u0631\u062d\u0644\u0629 {0}",//no i18n
"crm.module.owner":"\u0645\u0627\u0644\u0643 {0}",//no i18n
"Potential\ Closing\ Date":"{0} \u062a\u0627\u0631\u064a\u062e \u0627\u0644\u0625\u063a\u0644\u0627\u0642",//no i18n
"crm.lead.prediction.likely.convert":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647",//no i18n
"crm.predictions.feature.label":"\u0627\u0644\u062a\u0648\u0642\u0639",//no i18n
"crm.intelligence.prediction.likelywin":"\u064a\u0631\u062c\u062d \u0646\u062c\u0627\u062d\u0647",//no i18n
"crm.intelligence.prediction.likelylose":"\u064a\u0631\u062c\u062d \u0641\u0634\u0644\u0647",//no i18n
"crm.intelligence.prediction.halfchance":"\u0641\u0631\u0635\u0629 \u0628\u0646\u0633\u0628\u0629 50:50",//no i18n
"crm.intelligence.prediction.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624",//no i18n
"crm.lead.prediction.recent.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624 \u0627\u0644\u0623\u062e\u064a\u0631\u0629",//no i18n
"crm.intelligence.prediction.lastconv":"\u0622\u062e\u0631 3 \u0645\u062d\u0627\u062f\u062b\u0627\u062a",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0633\u062c\u0644\u0627\u062a \u0644\u0644\u062a\u0631\u0643\u064a\u0632",//no i18n
"crm.intelligence.prediction.slowmoving":"\u062a\u062d\u0631\u0643 \u0636\u0639\u064a\u0641",//no i18n
"crm.intelligence.prediction.trend.down":"\u062a\u062f\u0627\u0648\u0644 \u0645\u0646\u062e\u0641\u0636 \u0645\u0624\u062e\u0631\u064b\u0627",//no i18n
"crm.label.touched.records":"\u0633\u062c\u0644\u0627\u062a \u0645\u062a\u0623\u062b\u0631\u0629",//no i18n
"crm.label.untouched.records":"\u0633\u062c\u0644\u0627\u062a \u063a\u064a\u0631 \u0645\u062a\u0623\u062b\u0631\u0629",//no i18n
"crm.label.record.action":"\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0633\u062c\u0644",//no i18n
"workflow.rule.view.label.Modified":"\u062a\u0645 \u0627\u0644\u062a\u0639\u062f\u064a\u0644",//no i18n
"crm.label.not.modified":"\u063a\u064a\u0631 \u0645\u0639\u062f\u0644",//no i18n
"crm.label.related.records.action":"\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0633\u062c\u0644\u0627\u062a \u0630\u0627\u062a \u0627\u0644\u0635\u0644\u0629",//no i18n
"Done":"\u062a\u0645",//no i18n
"crm.label.not.done":"\u0644\u0645 \u064a\u062a\u0645",//no i18n
"sentiment.model":"\u062a\u0648\u062c\u0647 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"sentiment.criteria.count":"\u0627\u0644\u0639\u062f\u062f",//no i18n
"sentiment.criteria.percentage":"\u0646\u0633\u0628\u0629 \u0645\u0626\u0648\u064a\u0629",//no i18n
"sentiment.criteria.lastmail":"\u0644\u0622\u062e\u0631 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"Chats":"\u0645\u062d\u0627\u062f\u062b\u0627\u062a",//no i18n
"Attended":"\u062d\u0627\u0636\u0631",//no i18n
"crm.lead.prediction.popup.text":"\u0639\u0646\u062f\u0645\u0627 \u064a\u0643\u0648\u0646 \u062a\u0631\u062c\u064a\u062d \u0627\u0644\u062a\u062d\u0648\u064a\u0644 {0}\u060c \u064a\u062c\u0628 \u0623\u0646 \u062a\u0643\u0648\u0646 \u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624 \u0628\u064a\u0646 {1}.",//no i18n
"crm.lead.prediction.popup.final":"\u064a\u064f\u0631\u062c\u0649 \u062a\u063a\u064a\u064a\u0631 \u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629 \u0648\u0641\u0642\u064b\u0627 \u0644\u0630\u0644\u0643 \u0648\u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629.",//no i18n
"crm.custom.field.less.than.to1":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u064a\u0643\u0648\u0646 \u0646\u0637\u0627\u0642 \u201d\u0627\u0644\u0628\u062f\u0621\u201c \u0623\u0643\u0628\u0631 \u0645\u0646 \u0646\u0637\u0627\u0642 \u201d\u0627\u0644\u0627\u0646\u062a\u0647\u0627\u0621\u201c.",//no i18n
"Last\ Activity\ Date":"\u062a\u0627\u0631\u064a\u062e \u0622\u062e\u0631 \u0646\u0634\u0627\u0637",//no i18n
"crm.label.vendor.name":"\u0627\u0633\u0645 {0}",//no i18n
"hours":"\u0633\u0627\u0639\u0627\u062a",//no i18n
"days":"\u0627\u0644\u0623\u064a\u0627\u0645",//no i18n
"weeks":"\u0623\u0633\u0627\u0628\u064a\u0639",//no i18n
"months":"\u0634\u0647\u0648\u0631",//no i18n
"years":"\u0623\u0639\u0648\u0627\u0645",//no i18n
"crm.label.general.small.after":"\u0628\u0639\u062f",//no i18n
"Last\ Week":"\u0627\u0644\u0623\u0633\u0628\u0648\u0639 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"Last\ Month":"\u0627\u0644\u0634\u0647\u0631 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"crm.module.name":"\u0627\u0633\u0645 {0}",//no i18n
"Campaign":"\u062d\u0645\u0644\u0629",//no i18n
"Tasks":"\u0627\u0644\u0645\u0647\u0627\u0645",//no i18n
"Calls":"\u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0627\u062a",//no i18n
"Events":"\u0627\u0644\u0623\u062d\u062f\u0627\u062b",//no i18n
"sentiment.criteria.wrongcriteria":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u062a\u0643\u0648\u0646 \u0642\u064a\u0645\u0629 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631 \u0623\u0643\u062b\u0631 \u0645\u0646 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u064a\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 {0} \u062d\u0631\u0641\u0627 \u0623\u0648 \u0623\u0643\u062b\u0631",//no i18n
"crm.intelligence.prediction.trendup":"\u0627\u0644\u062a\u062f\u0627\u0648\u0644 \u0627\u0644\u0631\u0627\u0626\u062c",//no i18n
"crm.intelligence.prediction.trenddown":"\u0627\u0644\u062a\u062f\u0627\u0648\u0644 \u0627\u0644\u0645\u0646\u062e\u0641\u0636",//no i18n
"crm.condition.last.30.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 30 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.60.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 60 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.90.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 90 \u0623\u064a\u0627\u0645",//no i18n
"crm.sentiment.Positive":"\u0625\u064a\u062c\u0627\u0628\u064a",//no i18n
"crm.sentiment.Negative":"\u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveandnegative":"\u0625\u064a\u062c\u0627\u0628\u064a \u0648\u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveornegative":"\u0625\u064a\u062c\u0627\u0628\u064a \u0623\u0648 \u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveonly":"\u0625\u064a\u062c\u0627\u0628\u064a \u0641\u0642\u0637",//no i18n
"sentiment.negativeonly":"\u0633\u0644\u0628\u064a \u0641\u0642\u0637",//no i18n
"crm.sentiment.Neutral":"\u0645\u0639\u062a\u062f\u0644",//no i18n
"crm.filters.select.campaign.type":"\u062d\u062f\u062f \u0646\u0648\u0639 {0}",//no i18n
"crm.filters.select.campaign.status":"\u200e\u062a\u062d\u062f\u064a\u062f \u062d\u0627\u0644\u0629 \u200f\u200f \u200f{0}",//no i18n
"campaign.Member" : "\u0639\u0636\u0648",//no i18n
"Service":"\u0627\u0644\u062e\u062f\u0645\u0629",//no i18n
"Activities":"\u0627\u0644\u0623\u0646\u0634\u0637\u0629",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0623\u064a\u0627\u0645 \u062a\u0627\u0644\u064a\u0629",//no i18n
"Today\ +\ Overdue":"\u0627\u0644\u064a\u0648\u0645 + \u0627\u0644\u0645\u062a\u0623\u062e\u0631\u0629",//no i18n
"crm.source.user.and.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0648\u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"crm.source.user.or.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0623\u0648 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"User":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.source.user.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.source.system.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"Scheduled":"\u0645\u062c\u062f\u0648\u0644",//no i18n
"Attended\ Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0645\u0639 \u0627\u0644\u0631\u0642\u0627\u0628\u0629",//no i18n
"Unattended\ Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0628\u062f\u0648\u0646 \u0631\u0642\u0627\u0628\u0629",//no i18n
"Cancelled":"\u062a\u0645 \u0627\u0644\u0625\u0644\u063a\u0627\u0621",//no i18n
"crm.filter.email.isblocked":"\u0645\u062d\u0638\u0648\u0631",//no i18n
"crm.filter.email.isnotblocked":"\u063a\u064a\u0631 \u0645\u062d\u0638\u0648\u0631",//no i18n
"condition.till.now":"\u062d\u062a\u0649 \u0627\u0644\u0622\u0646",//no i18n
"crm.recurring.no.months":"{0} \u0623\u0634\u0647\u0631",//no i18n
"crm.lead.prediction.tooltip":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647 - \u0646\u0637\u0627\u0642 \u0627\u0644\u062f\u0631\u062c\u0629",//no i18n
"crm.website.activity":"\u0646\u0634\u0627\u0637 \u0627\u0644\u0645\u0648\u0642\u0639",//no i18n
"crm.label.More":"\u0627\u0644\u0645\u0632\u064a\u062f",//no i18n
"crm.label.By":"\u0628\u0648\u0627\u0633\u0637\u0629",//no i18n
"crm.chosen.searching.text":"\u062c\u0627\u0631 \u0627\u0644\u0628\u062d\u062b...",//no i18n
"crm.label.memberstatus.is":"\u0648\u062d\u0627\u0644\u0629 \u0627\u0644\u0639\u0636\u0648 \u0647\u064a",//no i18n
"crm.events.duration":"\u0627\u0644\u0645\u062f\u0629",//no i18n
"crm.title.clear.name":"\u0645\u0633\u062d",//no i18n
"crm.label.status.is":"\u0648\u0627\u0644\u062d\u0627\u0644\u0629 \u0647\u064a",//no i18n
"zia.last3.help":"\u0627\u0644\u0645\u062d\u0627\u062f\u062b\u0627\u062a \u062a\u0634\u0645\u0644 \u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0627\u062a \u0648\u0627\u0644\u0645\u0647\u0627\u0645 \u0648{0} \u0648\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0627\u0644\u0645\u0633\u062a\u0644\u0645 \u0648\u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a \u0648\u0627\u0644\u0632\u064a\u0627\u0631\u0627\u062a \u0648\u0627\u0644\u062a\u0639\u0644\u064a\u0642\u0627\u062a \u0627\u0644\u0627\u062c\u062a\u0645\u0627\u0639\u064a\u0629 \u0648\u0637\u0644\u0628\u0627\u062a \u0627\u0644\u062f\u0639\u0645 \u0645\u0646 \u0627\u0644\u0645\u0643\u062a\u0628.",//no i18n
"crm.label.tag.related.to":"\u0645\u0631\u062a\u0628\u0637 \u0628\u0640",//no i18n
"crm.alert.maximum.text.values.contains":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u0625\u062f\u062e\u0627\u0644 \u0623\u0643\u062b\u0631 \u0645\u0646 {0} \u0642\u064a\u0645 \u0644\u0647\u0630\u0627 \u0627\u0644\u062d\u0642\u0644.",//no i18n
"crm.lead.prediction.convert.high":"\u0645\u0631\u062a\u0641\u0639",//no i18n
"crm.lead.prediction.convert.medium":"\u0645\u062a\u0648\u0633\u0637",//no i18n
"crm.lead.prediction.convert.low":"\u0645\u0646\u062e\u0641\u0636",//no i18n
"crm.lead.prediction.focus.nofollowup":"\u0644\u0627 \u062a\u0648\u062c\u062f \u0645\u062a\u0627\u0628\u0639\u0629 \u062a\u0627\u0644\u064a\u0629",//no i18n
"crm.lead.prediction.likely.convert":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647",//no i18n
"crm.lead.prediction.recent.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624 \u0627\u0644\u0623\u062e\u064a\u0631\u0629",//no i18n
"crm.lead.prediction.tooltip":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647 - \u0646\u0637\u0627\u0642 \u0627\u0644\u062f\u0631\u062c\u0629",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0627\u0644\u062a\u0631\u062c\u064a\u062d",//no i18n
"crm.lead.prediction.tooltip.score":"\u0627\u0644\u062f\u0631\u062c\u0629",//no i18n
"Planned":"\u0645\u062e\u0637\u0637\u0629",//no i18n
"Planned":"\u0645\u062e\u0637\u0637\u0629",//no i18n
"Invited":"\u062a\u0645\u062a \u0627\u0644\u062f\u0639\u0648\u0629",//no i18n
"Sent":"\u0645\u0631\u0633\u0644\u0629",//no i18n
"Failed":"\u0641\u0634\u0644",//no i18n
"Opened":"\u062a\u0645 \u0627\u0644\u0641\u062a\u062d",//no i18n
"Bounced":"\u062a\u0645 \u0627\u0644\u0627\u0631\u062a\u062f\u0627\u062f",//no i18n
"Responded":"\u062a\u0645 \u0627\u0644\u0631\u062f",//no i18n
"Opted\ Out":"\u0627\u0644\u0627\u0646\u0633\u062d\u0627\u0628",//no i18n
"Clicked":"\u062a\u0645 \u0627\u0644\u0646\u0642\u0631",//no i18n
"Marked.as.spam":"\u062a\u0645 \u0627\u0644\u062a\u0645\u064a\u064a\u0632 \u0643\u0628\u0631\u064a\u062f \u0639\u0634\u0648\u0627\u0626\u064a",//no i18n
"Member\ Status":"\u062d\u0627\u0644\u0629 \u0627\u0644\u0639\u0636\u0648",//no i18n
"Camp\ Member\ Status":"\u062d\u0627\u0644\u0629 \u0639\u0636\u0648 \u0627\u0644\u062d\u0645\u0644\u0629",//no i18n
"Campaign\ Member":"\u0639\u0636\u0648 \u0627\u0644\u062d\u0645\u0644\u0629",//no i18n
"crm.zinvoice.dueIn":"\u0645\u0633\u062a\u062d\u0642 \u0641\u064a",//no i18n
"crm.zinvoice.dueby":"\u062a\u0627\u0631\u064a\u062e \u200e\u0627\u0644\u0627\u0633\u062a\u062d\u0642\u0627\u0642 \u200f\u200f \u200f",//no i18n
"Rollbacked":"\u062a\u0645 \u0627\u0644\u0625\u0631\u062c\u0627\u0639",//no i18n
"crm.filter.label.with.open":"\u0645\u0639 {0} \u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.with.module":"\u0645\u0639 {0}",//no i18n
"crm.filter.label.activity.due":"{0} \u0645\u0633\u062a\u062d\u0642",//no i18n
"crm.filter.label.activity.done":"{0} \u062a\u0627\u0645",//no i18n
"Date":"\u0627\u0644\u062a\u0627\u0631\u064a\u062e",//no i18n
"crm.filter.label.notes.added":"\u0645\u0644\u0627\u062d\u0638\u0627\u062a \u0645\u0636\u0627\u0641\u0629",//no i18n
"crm.label.filter.email.status":"\u062d\u0627\u0644\u0629 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0627\u0644\u0623\u062e\u064a\u0631\u0629",//no i18n
"crm.filter.label.sent":"\u0645\u0631\u0633\u0644",//no i18n
"crm.filter.label.not.sent":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0625\u0631\u0633\u0627\u0644",//no i18n
"crm.filter.label.opened":"\u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.not.opened":"\u063a\u064a\u0631 \u0645\u0641\u062a\u0648\u062d",//no i18n
"crm.filter.label.received":"\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"crm.filter.label.not.received":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645",//no i18n
"crm.filter.label.bounced":"\u0645\u0631\u062a\u062f",//no i18n
"crm.filter.label.opened.not.replied":"\u0645\u0641\u062a\u0648\u062d \u0648\u0644\u0643\u0646 \u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0631\u062f",//no i18n
"crm.filter.label.any":"\u0623\u064a \u0645\u0645\u0627 \u0633\u0628\u0642",//no i18n
"crm.zia.config.potential.amount":"\u0645\u0628\u0644\u063a {0}",//no i18n
"Quote\ Stage":"\u0645\u0631\u062d\u0644\u0629 {0}",//no i18n
"crm.module.owner":"\u0645\u0627\u0644\u0643 {0}",//no i18n
"crm.label.Potential\ Closing\ Date":"\u062a\u0627\u0631\u064a\u062e \u0625\u063a\u0644\u0627\u0642 \u0627\u0644\u0641\u0631\u0635\u0629",//no i18n
"crm.lead.prediction.likely.convert":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647",//no i18n
"crm.intelligence.prediction.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624",//no i18n
"crm.lead.prediction.recent.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624 \u0627\u0644\u0623\u062e\u064a\u0631\u0629",//no i18n
"crm.intelligence.prediction.lastconv":"\u0622\u062e\u0631 3 \u0645\u062d\u0627\u062f\u062b\u0627\u062a",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0633\u062c\u0644\u0627\u062a \u0644\u0644\u062a\u0631\u0643\u064a\u0632",//no i18n
"crm.intelligence.prediction.slowmoving":"\u062a\u062d\u0631\u0643 \u0636\u0639\u064a\u0641",//no i18n
"crm.intelligence.prediction.trend.down":"\u062a\u062f\u0627\u0648\u0644 \u0645\u0646\u062e\u0641\u0636 \u0645\u0624\u062e\u0631\u064b\u0627",//no i18n
"crm.label.touched.records":"\u0633\u062c\u0644\u0627\u062a \u0645\u062a\u0623\u062b\u0631\u0629",//no i18n
"crm.label.untouched.records":"\u0633\u062c\u0644\u0627\u062a \u063a\u064a\u0631 \u0645\u062a\u0623\u062b\u0631\u0629",//no i18n
"crm.label.record.action":"\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0633\u062c\u0644",//no i18n
"workflow.rule.view.label.Modified":"\u062a\u0645 \u0627\u0644\u062a\u0639\u062f\u064a\u0644",//no i18n
"crm.label.not.modified":"\u063a\u064a\u0631 \u0645\u0639\u062f\u0644",//no i18n
"crm.label.related.records.action":"\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0633\u062c\u0644\u0627\u062a \u0630\u0627\u062a \u0627\u0644\u0635\u0644\u0629",//no i18n
"Done":"\u062a\u0645",//no i18n
"crm.label.not.done":"\u0644\u0645 \u064a\u062a\u0645",//no i18n
"sentiment.model":"\u062a\u0648\u062c\u0647 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"sentiment.criteria.count":"\u0627\u0644\u0639\u062f\u062f",//no i18n
"sentiment.criteria.percentage":"\u0646\u0633\u0628\u0629 \u0645\u0626\u0648\u064a\u0629",//no i18n
"sentiment.criteria.lastmail":"\u0644\u0622\u062e\u0631 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"Chats":"\u0645\u062d\u0627\u062f\u062b\u0627\u062a",//no i18n
"Attended":"\u062d\u0627\u0636\u0631",//no i18n
"crm.warning.select.filter":"\u0627\u062e\u062a\u0631 \u062d\u0642\u0644\u064b\u0627 \u0648\u0627\u062d\u062f\u064b\u0627 \u0639\u0644\u0649 \u0627\u0644\u0623\u0642\u0644 \u0644\u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629.",//no i18n
"crm.lead.prediction.popup.text":"\u0639\u0646\u062f\u0645\u0627 \u064a\u0643\u0648\u0646 \u062a\u0631\u062c\u064a\u062d \u0627\u0644\u062a\u062d\u0648\u064a\u0644 {0}\u060c \u064a\u062c\u0628 \u0623\u0646 \u062a\u0643\u0648\u0646 \u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0646\u0628\u0624 \u0628\u064a\u0646 {1}.",//no i18n
"crm.lead.prediction.popup.final":"\u064a\u064f\u0631\u062c\u0649 \u062a\u063a\u064a\u064a\u0631 \u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629 \u0648\u0641\u0642\u064b\u0627 \u0644\u0630\u0644\u0643 \u0648\u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629.",//no i18n
"crm.custom.field.less.than.to":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u064a\u0643\u0648\u0646 \u0646\u0637\u0627\u0642 <i>\u0627\u0644\u0628\u062f\u0621</i> \u0623\u0643\u0628\u0631 \u0645\u0646 \u0646\u0637\u0627\u0642 <i>\u0627\u0644\u0627\u0646\u062a\u0647\u0627\u0621</i>.",//no i18n
"Last\ Activity\ Date":"\u062a\u0627\u0631\u064a\u062e \u0622\u062e\u0631 \u0646\u0634\u0627\u0637",//no i18n
"Contact\ Name":"\u0627\u0633\u0645 {0}",//no i18n
"crm.lead.prediction.tooltip":"\u064a\u0631\u062c\u062d \u062a\u062d\u0648\u064a\u0644\u0647 - \u0646\u0637\u0627\u0642 \u0627\u0644\u062f\u0631\u062c\u0629",//no i18n
"crm.website.activity":"\u0646\u0634\u0627\u0637 \u0627\u0644\u0645\u0648\u0642\u0639",//no i18n
"days":"\u0627\u0644\u0623\u064a\u0627\u0645",//no i18n
"weeks":"\u0623\u0633\u0627\u0628\u064a\u0639",//no i18n
"months":"\u0634\u0647\u0648\u0631",//no i18n
"crm.condition.in.last":"\u0641\u064a \u0627\u0644\u0623\u062e\u064a\u0631",//no i18n
"before":"\u0642\u0628\u0644",//no i18n
"crm.label.general.small.after":"\u0628\u0639\u062f",//no i18n
"between":"\u0628\u064a\u0646",//no i18n
"Today":"\u0627\u0644\u064a\u0648\u0645",//no i18n
"Yesterday":"\u0623\u0645\u0633",//no i18n
"crm.thisweek":"\u0647\u0630\u0627 \u0627\u0644\u0623\u0633\u0628\u0648\u0639",//no i18n
"crm.label.this.month":"\u0647\u0630\u0627 \u0627\u0644\u0634\u0647\u0631",//no i18n
"crm.thisyear":"\u0647\u0630\u0627 \u0627\u0644\u0639\u0627\u0645",//no i18n
"Last\ Week":"\u0627\u0644\u0623\u0633\u0628\u0648\u0639 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"Last\ Month":"\u0627\u0644\u0634\u0647\u0631 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"crm.intelligence.prediction.trendup":"\u0627\u0644\u062a\u062f\u0627\u0648\u0644 \u0627\u0644\u0631\u0627\u0626\u062c",//no i18n
"crm.intelligence.prediction.trenddown":"\u0627\u0644\u062a\u062f\u0627\u0648\u0644 \u0627\u0644\u0645\u0646\u062e\u0641\u0636",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\u0645\u0643\u062a\u0645\u0644" ,//no i18n
"crm.label.success":"\u0646\u0627\u062c\u062d\u0629" ,//no i18n
"crm.label.Failure":"\u0641\u0634\u0644" ,//no i18n
"Both":"\u0643\u0644\u0627\u0647\u0645\u0627" ,//no i18n
"crm.condition.cannot.empty":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u062a\u0631\u0643 \u0627\u0644\u0634\u0631\u0637 \u0641\u0627\u0631\u063a\u064b\u0627.",//no i18n
"crm.condition.last.30.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 30 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.60.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 60 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.last.90.days":"\u062e\u0644\u0627\u0644 \u0622\u062e\u0631 90 \u0623\u064a\u0627\u0645",//no i18n
"crm.condition.until.now":"\u062d\u062a\u0649 \u0627\u0644\u0622\u0646",//no i18n
"crm.sentiment.Positive":"\u0625\u064a\u062c\u0627\u0628\u064a",//no i18n
"crm.sentiment.Negative":"\u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveandnegative":"\u0625\u064a\u062c\u0627\u0628\u064a \u0648\u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveornegative":"\u0625\u064a\u062c\u0627\u0628\u064a \u0623\u0648 \u0633\u0644\u0628\u064a",//no i18n
"sentiment.positiveonly":"\u0625\u064a\u062c\u0627\u0628\u064a \u0641\u0642\u0637",//no i18n
"sentiment.negativeonly":"\u0633\u0644\u0628\u064a \u0641\u0642\u0637",//no i18n
"crm.sentiment.Neutral":"\u0645\u0639\u062a\u062f\u0644",//no i18n
"crm.module.name":"\u0627\u0633\u0645 {0}",//no i18n
"Campaign":"\u062d\u0645\u0644\u0629",//no i18n
"crm.filters.select.campaign.type":"\u062d\u062f\u062f \u0646\u0648\u0639 {0}",//no i18n
"Advertisement":"\u0625\u0639\u0644\u0627\u0646",//no i18n
"Banner\ Ads":"\u0625\u0639\u0644\u0627\u0646\u0627\u062a \u0627\u0644\u0634\u0639\u0627\u0631",//no i18n
"Conference":"\u0645\u0624\u062a\u0645\u0631",//no i18n
"Direct\ mail":"\u0628\u0631\u064a\u062f \u0645\u0628\u0627\u0634\u0631",//no i18n
"crm.taskreminder.line8":"\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"Others":"\u0623\u062e\u0631\u0649",//no i18n
"Partner":"\u0634\u0631\u064a\u0643",//no i18n
"Public\ Relations":"\u0639\u0644\u0627\u0642\u0627\u062a \u0639\u0627\u0645\u0629",//no i18n
"Referral\ Program":"\u0628\u0631\u0646\u0627\u0645\u062c \u0627\u0644\u0625\u062d\u0627\u0644\u0629",//no i18n
"Telemarketing":"\u0627\u0644\u062a\u0633\u0648\u064a\u0642 \u0639\u0628\u0631 \u0627\u0644\u0647\u0627\u062a\u0641",//no i18n
"Trade\ Show":"\u0645\u0639\u0631\u0636 \u062a\u062c\u0627\u0631\u064a",//no i18n
"Webinar":"\u0646\u062f\u0648\u0629 \u0639\u0644\u0649 \u0627\u0644\u0648\u064a\u0628",//no i18n
"crm.filters.select.campaign.status":"\u200e\u062a\u062d\u062f\u064a\u062f \u062d\u0627\u0644\u0629 \u200f\u200f \u200f{0}",//no i18n
"Active":"\u0646\u0634\u0637",//no i18n
"Complete":"\u0645\u0643\u062a\u0645\u0644",//no i18n
"Inactive":"\u063a\u064a\u0631 \u0646\u0634\u0637",//no i18n
"Planning":"\u062a\u062e\u0637\u064a\u0637",//no i18n
"Activities":"\u0627\u0644\u0623\u0646\u0634\u0637\u0629",//no i18n
"Tasks":"\u0627\u0644\u0645\u0647\u0627\u0645",//no i18n
"Calls":"\u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0627\u062a",//no i18n
"Tomorrow":"\u063a\u062f\u064b\u0627",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0623\u064a\u0627\u0645 \u062a\u0627\u0644\u064a\u0629",//no i18n
"Today\ +\ Overdue":"\u0627\u0644\u064a\u0648\u0645 + \u0627\u0644\u0645\u062a\u0623\u062e\u0631\u0629",//no i18n
"crm.source.user.and.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0648\u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"crm.source.user.or.system":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645 \u0623\u0648 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"User":"\u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.source.user.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",//no i18n
"crm.source.system.only":"\u0641\u0642\u0637 \u062d\u0633\u0628 \u0627\u0644\u0646\u0638\u0627\u0645",//no i18n
"Scheduled":"\u0645\u062c\u062f\u0648\u0644",//no i18n
"Attended\ Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0645\u0639 \u0627\u0644\u0631\u0642\u0627\u0628\u0629",//no i18n
"Unattended\ Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0628\u062f\u0648\u0646 \u0631\u0642\u0627\u0628\u0629",//no i18n
"Cancelled":"\u062a\u0645 \u0627\u0644\u0625\u0644\u063a\u0627\u0621",//no i18n
"crm.filter.email.isblocked":"\u0645\u062d\u0638\u0648\u0631",//no i18n
"crm.filter.email.isnotblocked":"\u063a\u064a\u0631 \u0645\u062d\u0638\u0648\u0631",//no i18n
"crm.condition.till.today":"\u062d\u062a\u0649 \u0627\u0644\u064a\u0648\u0645",//no i18n
"crm.recurring.no.months":"{0} \u0623\u0634\u0647\u0631",//no i18n
"sentiment.criteria.wrongcriteria":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u062a\u0643\u0648\u0646 \u0642\u064a\u0645\u0629 \u0627\u0644\u0645\u0639\u0627\u064a\u064a\u0631 \u0623\u0643\u062b\u0631 \u0645\u0646 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u064a\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 {0} \u062d\u0631\u0641\u0627 \u0623\u0648 \u0623\u0643\u062b\u0631",//no i18n
"crm.chosen.searching.text":"\u062c\u0627\u0631 \u0627\u0644\u0628\u062d\u062b...",//no i18n
"is":"\u064a\u0643\u0648\u0646",//no i18n
"crm.label.memberstatus.is":"\u0648\u062d\u0627\u0644\u0629 \u0627\u0644\u0639\u0636\u0648 \u0647\u064a",//no i18n
"crm.events.duration":"\u0627\u0644\u0645\u062f\u0629",//no i18n
"and":"\u0648",//no i18n
"crm.title.clear.name":"\u0645\u0633\u062d",//no i18n
"zia.last3.help":"\u0627\u0644\u0645\u062d\u0627\u062f\u062b\u0627\u062a \u062a\u0634\u0645\u0644 \u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0627\u062a \u0648\u0627\u0644\u0645\u0647\u0627\u0645 \u0648{0} \u0648\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0627\u0644\u0645\u0633\u062a\u0644\u0645 \u0648\u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a \u0648\u0627\u0644\u0632\u064a\u0627\u0631\u0627\u062a \u0648\u0627\u0644\u062a\u0639\u0644\u064a\u0642\u0627\u062a \u0627\u0644\u0627\u062c\u062a\u0645\u0627\u0639\u064a\u0629 \u0648\u0637\u0644\u0628\u0627\u062a \u0627\u0644\u062f\u0639\u0645 \u0645\u0646 \u0627\u0644\u0645\u0643\u062a\u0628.",//no i18n
"crm.label.By":"\u0628\u0648\u0627\u0633\u0637\u0629",//no i18n
"crm.label.add.note":"\u200e\u0625\u0636\u0627\u0641\u0629 \u0645\u0644\u0627\u062d\u0638\u0629 \u200f",//no i18n
"crm.label.simply.by":"\u062d\u0633\u0628",//no i18n
"crm.general.addnote":"\u0625\u0636\u0627\u0641\u0629 \u0645\u0644\u0627\u062d\u0638\u0629",//no i18n
"crm.general.addtitle":"\u0625\u0636\u0627\u0641\u0629 \u0639\u0646\u0648\u0627\u0646",//no i18n
"crm.label.attach.file":"\u0625\u0631\u0641\u0627\u0642 \u0645\u0644\u0641",//no i18n
"crm.button.mass.delete":"\u062d\u0630\u0641",//no i18n
"crm.warning.delete.record":"\u0647\u0644 \u0623\u0646\u062a \u0645\u062a\u0623\u0643\u062f \u0623\u0646\u0643 \u062a\u0631\u064a\u062f \u062d\u0630\u0641 \"{0}\"\u061f",//no i18n
"crm.label.yes":"\u0646\u0639\u0645",//no i18n
"crm.note.view.previous":"\u0639\u0631\u0636 \u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a \u0627\u0644\u0633\u0627\u0628\u0642\u0629",//no i18n
"of":"\u0645\u0646",//no i18n
"crm.label.notes":"\u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a",//no i18n
"crm.note.recent.first":"\u0627\u0644\u0623\u0648\u0644 \u0627\u0644\u0623\u062d\u062f\u062b",//no i18n
"crm.note.recent.last":"\u0627\u0644\u0623\u062e\u064a\u0631 \u0627\u0644\u0623\u062d\u062f\u062b",//no i18n
"crm.territory.label.only":"{0} \u0641\u0642\u0637",//no i18n
"crm.button.apply.filter":"\u062a\u0637\u0628\u064a\u0642 \u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629",//no i18n
"PM":"\u0645\u0633\u0627\u0621\u064b",//no i18n
//start-cx create form keys
"crm.duplicate.value.not.allowed": "\u0627\u0644\u0642\u064a\u0645 \u0627\u0644\u0645\u0643\u0631\u0631\u0629 \u063a\u064a\u0631 \u0645\u0633\u0645\u0648\u062d \u0628\u0647\u0627.",//no i18n
"crm.duplicate.value.available": "\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 {0} \u062d\u0627\u0644\u064a\u0629 \u0644\u0647\u0627 \u0646\u0641\u0633 {1}.",//no i18n
"crm.duplicate.value.available.multiple": "\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0623\u0643\u062b\u0631 \u0645\u0646 {0} \u0648\u0627\u062d\u062f\u0629 \u0644\u0647\u0627 \u0646\u0641\u0633 {1}.",//no i18n
"crm.custombutton.nobuttons.found": "\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0623\u0632\u0631\u0627\u0631",//no i18n
"crm.custombutton.create.newbutton": "\u0625\u0646\u0634\u0627\u0621 \u0632\u0631",//no i18n
"crm.custombutton.manage.button": "\u0625\u062f\u0627\u0631\u0629 \u0627\u0644\u0623\u0632\u0631\u0627\u0631",//no i18n
"crm.custombutton.name": "\u0627\u0644\u0627\u0633\u0645",//no i18n
"crm.customize.custombutton.function.desc": "\u0627\u0644\u0648\u0635\u0641",//no i18n
"crm.custombutton.action": "\u0625\u062c\u0631\u0627\u0621 \u0627\u0644\u0632\u0631",//no i18n
"crm.custombutton.empty.field.values": "\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0642\u064a\u0645\u0629 \u0641\u0627\u0631\u063a\u0629 \u0644\u0644\u062d\u0642\u0644 (\u0644\u0644\u062d\u0642\u0648\u0644) \u0627\u0644\u062a\u0627\u0644\u064a:",//no i18n
"crm.custombutton.proceed.action": "\u0647\u0644 \u0645\u0627 \u0632\u0644\u062a \u062a\u0631\u064a\u062f \u0627\u0644\u0645\u062a\u0627\u0628\u0639\u0629\u061f",//no i18n
"zb.common.Cancel": "\u0625\u0644\u063a\u0627\u0621",//no i18n
"crm.yes.proceed": "\u0623\u062c\u0644\u060c \u062a\u0642\u062f\u0645",//no i18n
"crm.label.module.merge": "\u062f\u0645\u062c {0}",//no i18n
"crm.view.record": "\u0639\u0631\u0636 {0}",//no i18n
"crm.tpi.ctiapi.config.choosefile": "\u0623\u062f\u062e\u0644 \u0645\u0644\u0641",//no i18n
"crm.label.subform.addrows": "\u0625\u0636\u0627\u0641\u0629 \u0635\u0641",//no i18n
"crm.button.save&new": "\u062d\u0641\u0638 \u0648\u0628\u062f\u0621 \u0645\u0644\u0641 \u062c\u062f\u064a\u062f",//no i18n
//end-cx create form keys
"crm.mb.newversion.msg4":"\u062d\u0633\u0646\u064b\u0627\u060c \u0641\u0647\u0645\u062a!",//no i18n
"Jan":"\u064a\u0646\u0627\u064a\u0631",//no i18n
"Feb":"\u0641\u0628\u0631\u0627\u064a\u0631",//no i18n
"Mar":"\u0645\u0627\u0631\u0633",//no i18n
"Apr":"\u0623\u0628\u0631\u064a\u0644",//no i18n
"Jun":"\u064a\u0648\u0646\u064a\u0648",//no i18n
"Jul":"\u064a\u0648\u0644\u064a\u0647",//no i18n
"Aug":"\u0623\u063a\u0633\u0637\u0633",//no i18n
"Sep":"\u0633\u0628\u062a\u0645\u0628\u0631",//no i18n
"Oct":"\u0623\u0643\u062a\u0648\u0628\u0631",//no i18n
"Nov":"\u0646\u0648\u0641\u0645\u0628\u0631",//no i18n
"Dec":"\u062f\u064a\u0633\u0645\u0628\u0631",//no i18n
"crm.label.unmapped.stages":"\u063a\u064a\u0631 \u0645\u062d\u0633\u0648\u0628",//no i18n
"crm.wf.summary.label.ShowInstantActions":"\u0625\u0638\u0647\u0627\u0631 \u0627\u0644\u0645\u0632\u064a\u062f",//no i18n
"crm.wf.summary.label.HideInstantActions":"\u0625\u0638\u0647\u0627\u0631 \u0623\u0642\u0644",//no i18n
"crm.label.account.created":"\u0633\u064a\u062a\u0645 \u0625\u0646\u0634\u0627\u0621 {0} \u062c\u062f\u064a\u062f.",//no i18n
"crm.krp.no.records.found":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0623\u064a {0}",//no i18n
"crm.module.new":"{0} \u062c\u062f\u064a\u062f",//no i18n
"crm.label.view":"\u0639\u0631\u0636",//no i18n
"crm.nsocial.customers":"\u0627\u0644\u0639\u0645\u0644\u0627\u0621",//no i18n
"crm.nsocial.open.potential":"{0} \u0627\u0644\u0645\u0641\u062a\u0648\u062d\u0629",//no i18n
"crm.nsocial.lead.contact":"{0}/{1}",//no i18n
"Others":"\u0623\u062e\u0631\u0649",//no i18n
"crm.field.length.check":"\u062a\u062a\u062c\u0627\u0648\u0632 \u0642\u064a\u0645\u0629 {0} \u0623\u0642\u0635\u0649 \u0637\u0648\u0644.",//no i18n
"crm.lower.now":"\u0627\u0644\u0622\u0646",//no i18n
"crm.time.min.ago":"\u0645\u0646\u0630 {0} \u062f\u0642\u0627\u0626\u0642",//no i18n
"crm.time.mins.ago":"\u0645\u0646\u0630 {0} \u062f\u0642\u0627\u0626\u0642",//no i18n
"crm.time.hr.ago":"\u0645\u0646\u0630 {0} \u0633\u0627\u0639\u0629",//no i18n
"crm.time.hrs.ago":"\u0645\u0646\u0630 {0} \u0633\u0627\u0639\u0627\u062a",//no i18n
"AllUsers":"\u0643\u0644 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645\u064a\u0646",//no i18n
"crm.label.search":"\u0628\u062d\u062b",//no i18n
"crm.api.filterby":"\u062a\u0635\u0641\u064a\u0629 \u062d\u0633\u0628",//no i18n
"crm.customview.nofields.found":"--\u0644\u0627 \u062a\u0648\u062c\u062f \u062d\u0642\u0648\u0644 \u0645\u062a\u0637\u0627\u0628\u0642\u0629--",//no i18n
"crm.setup.system.ziarecommendation":"\u062a\u0648\u0635\u064a\u0629",//no i18n
"crm.filter.label.all.products":"\u0643\u0627\u0641\u0629 {0}",//no i18n
"crm.filter.label.select.products":"{0} \u0627\u0644\u0645\u062d\u062f\u062f",//no i18n
"crm.reviewprocess.smart.filter":"\u062d\u0627\u0644\u0629 \u0633\u062c\u0644 \u0639\u0645\u0644\u064a\u0629 \u0627\u0644\u0645\u0631\u0627\u062c\u0639\u0629",//no i18n
"crm.dashboard.sharing.empty.value.alert":"\u064a\u064f\u0631\u062c\u0649 \u0627\u062e\u062a\u064a\u0627\u0631 \u0642\u064a\u0645\u0629.",//no i18n
"crm.segmentation.segment.score":"\u0646\u062a\u064a\u062c\u0629 \u0627\u0644\u0645\u0642\u0637\u0639",//no i18n
"crm.filter.label.in":"\u0648\u0641\u0642\u064b\u0627 \u0644\u0640",//no i18n
"crm.filter.label.and.purchase.in":"\u0648\u064a\u062d\u062a\u0645\u0644 \u0627\u0644\u0634\u0631\u0627\u0621 \u0641\u064a",//no i18n
"crm.filter.label.last.purchased":"\u0648\u062a\u0645 \u0627\u0643\u062a\u0633\u0627\u0628\u0647 \u0645\u0624\u062e\u0631\u064b\u0627",//no i18n
"crm.filter.label.a.day":"\u064a\u0648\u0645",//no i18n
"crm.filter.label.a.week":"\u0623\u0633\u0628\u0648\u0639",//no i18n
"crm.filter.label.a.month":"\u0634\u0647\u0631",//no i18n
"crm.cal.custom":"\u0645\u062e\u0635\u0635",//no i18n
"crm.mb.field.common.empt":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0623\u0646 \u062a\u0643\u0648\u0646 \u0627\u0644\u0642\u064a\u0645\u0629 \u0641\u0627\u0631\u063a\u0629.",//no i18n
"crm.chosen.error.loading.text":"\u0639\u0641\u0648\u064b\u0627\u060c \u0644\u0645 \u0646\u062a\u0645\u0643\u0646 \u0645\u0646 \u062a\u062d\u0645\u064a\u0644 \u0646\u062a\u0627\u0626\u062c\u0643",//no i18n
"crm.filter.label.firstbuy":"\u0623\u0648\u0644 \u0645\u0631\u0629",//no i18n
"crm.filter.label.cwbab":"\u0627\u0644\u062a\u0627\u0628\u0639",//no i18n
"crm.filter.label.fbt":"\u0627\u0644\u0645\u062c\u0645\u0648\u0639\u0629",//no i18n
"crm.filter.label.rebuy":"\u062a\u0643\u0631\u0627\u0631",//no i18n
"crm.filter.label.nextbuy":"\u0627\u0644\u062a\u0633\u0644\u0633\u0644",//no i18n
"crm.mxnlookup.select":"\u062a\u0639\u064a\u064a\u0646 {0}",//no i18n
"crm.lookup.chooserecord":"\u0627\u062e\u062a\u0631 {0}",//no i18n
"crm.record.selected":"{0} \u0627\u0644\u0645\u062d\u062f\u062f",//no i18n
"crm.module.empty.message":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0623\u064a {0}",//no i18n
"crm.mxnlookup.selected":"\u062a\u0645 \u062a\u0639\u064a\u064a\u0646 {0}",//no i18n
"crm.no.data.found":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0628\u064a\u0627\u0646\u0627\u062a",//no i18n
"doesn\'t contain":"\u0644\u0627 \u064a\u062d\u062a\u0648\u064a \u0639\u0644\u0649",//no i18n
"starts with":"\u200e\u064a\u0628\u062f\u0623 \u0628\u0640 \u200f",//no i18n
"ends with":"\u064a\u0646\u062a\u0647\u064a \u0628\u0640",//no i18n
"is empty":"\u064a\u0643\u0648\u0646 \u0641\u0627\u0631\u063a",//no i18n
"is not empty":"\u063a\u064a\u0631 \u0641\u0627\u0631\u063a",//no i18n
"is before":"\u0642\u0628\u0644",//no i18n
"is after":"\u0628\u0639\u062f",//no i18n
"not between":"\u0644\u064a\u0633 \u0628\u064a\u0646",//no i18n
"Tommorow Onwards":"\u064a\u0628\u062f\u0623 \u0645\u0646 \u0627\u0644\u063a\u062f",//no i18n
"Till Yesterday":"\u062d\u062a\u0649 \u0627\u0644\u0623\u0645\u0633",//no i18n
"Last Month":"\u0627\u0644\u0634\u0647\u0631 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"Current Month":"\u0627\u0644\u0634\u0647\u0631 \u0627\u0644\u062d\u0627\u0644\u064a",//no i18n
"Next Month":"\u0627\u0644\u0634\u0647\u0631 \u0627\u0644\u0642\u0627\u062f\u0645",//no i18n
"Last Week":"\u0627\u0644\u0623\u0633\u0628\u0648\u0639 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"Current Week":"\u0627\u0644\u0623\u0633\u0628\u0648\u0639 \u0627\u0644\u062d\u0627\u0644\u064a",//no i18n
"Next Week":"\u0627\u0644\u0623\u0633\u0628\u0648\u0639 \u0627\u0644\u0645\u0642\u0628\u0644",//no i18n
"Age in Days":"\u0627\u0644\u0639\u0645\u0631 \u0628\u0627\u0644\u0623\u064a\u0627\u0645",//no i18n
"Due in Days":"\u0645\u0633\u062a\u062d\u0642 \u062e\u0644\u0627\u0644 \u0623\u064a\u0627\u0645",//no i18n
"Attended Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0645\u0639 \u0627\u0644\u0631\u0642\u0627\u0628\u0629",//no i18n
"Unattended Dialled":"\u062a\u0645 \u0627\u0644\u0637\u0644\u0628 \u0628\u062f\u0648\u0646 \u0631\u0642\u0627\u0628\u0629",//no i18n
"crm.security.error" : "\u0644\u064a\u0633 \u0644\u062f\u064a\u0643 \u0627\u0644\u0623\u0630\u0648\u0646\u0627\u062a \u0627\u0644\u0643\u0627\u0641\u064a\u0629 \u0644\u062a\u0646\u0641\u064a\u0630 \u0647\u0630\u0647 \u0627\u0644\u0639\u0645\u0644\u064a\u0629. \u0627\u062a\u0635\u0644 \u0628\u0627\u0644\u0645\u0633\u0624\u0648\u0644 \u0627\u0644\u062e\u0627\u0635 \u0628\u0643.", //no i18n
"crm.label.creator.noPermission" : "\u062a\u0645 \u0631\u0641\u0636 \u0627\u0644\u0625\u0630\u0646", //no i18n
"crm.segmentation.recency"  : "\u0627\u0644\u062d\u062f\u0627\u062b\u0629", //no i18n
"crm.segmentation.frequency" : "\u0627\u0644\u062a\u0643\u0631\u0627\u0631",//no i18n
"crm.segmentation.monetary" : "\u0627\u0644\u0645\u0628\u0627\u0644\u063a \u0627\u0644\u0646\u0642\u062f\u064a\u0629",//no i18n
"crm.smartfilter.related.module.msg" : "\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0623\u0643\u062b\u0631 \u0645\u0646 \u062b\u0644\u0627\u062b \u0648\u062d\u062f\u0627\u062a \u0646\u0645\u0637\u064a\u0629 \u0645\u0631\u062a\u0628\u0637\u0629.",//no i18n
"crm.smartfilter.related.module.msg1" : " (\u0645\u062b\u0627\u0644: \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0648\u0627\u0644\u0646\u0634\u0627\u0637\u0627\u062a \u0648\u0627\u0644\u0645\u0644\u0627\u062d\u0638\u0627\u062a)", //no i18n
"crm.smartfilter.related.module.msg2" : "\u064a\u062a\u0639\u0630\u0631 \u062a\u0631\u0643 \u0627\u0644\u0641\u062a\u0631\u0629 \u0641\u0627\u0631\u063a\u0629", //no i18n
"crm.label.timeZone": "\u0627\u0644\u0645\u0646\u0637\u0642\u0629 \u0627\u0644\u0632\u0645\u0646\u064a\u0629", //NO I18n
"crm.label.insufficient.privileges": "\u0627\u0645\u062a\u064a\u0627\u0632\u0627\u062a \u063a\u064a\u0631 \u0643\u0627\u0641\u064a\u0629 \u0644\u0625\u062c\u0631\u0627\u0621 \u0647\u0630\u0647 \u0627\u0644\u0639\u0645\u0644\u064a\u0629. \u0627\u062a\u0635\u0644 \u0628\u0627\u0644\u0645\u0633\u0624\u0648\u0644.", //NO I18n
"crm.filter.header.secton.system": "\u0639\u0648\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629 \u0627\u0644\u0645\u062d\u062f\u062f\u0629 \u0628\u0627\u0644\u0646\u0638\u0627\u0645", //NO I18N
"crm.filter.header.secton.fields": "\u062a\u0635\u0641\u064a\u0629 \u062d\u0633\u0628 \u0627\u0644\u062d\u0642\u0648\u0644", //NO I18N
"crm.createfield.calcinfo.new" : "\u0647\u0630\u0627 \u0627\u0644\u062d\u0642\u0644 \u064a\u0639\u0645\u0644 \u0643\u0622\u0644\u0629 \u062d\u0627\u0633\u0628\u0629 \u0644\u0623\u064a \u062a\u0639\u0628\u064a\u0631\u0627\u062a \u062a\u0642\u0648\u0645 \u0628\u0625\u062f\u062e\u0627\u0644\u0647\u0627.</br> <b>\u0645\u062b\u0627\u0644: 20 + 20 </b>\u0633\u0648\u0641 \u062a\u0646\u062a\u062c <b>40</b> \u062a\u0644\u0642\u0627\u0626\u064a\u064b\u0627",//No i18n
"crm.lable.read.only" : "\u062d\u0642\u0644 \u0644\u0644\u0642\u0631\u0627\u0621\u0629 \u0641\u0642\u0637",//No i18n
"crm.column.sort.asc" : "\u062a\u0635\u0627\u0639\u062f\u064a",//No I18n
"crm.column.sort.desc" : "\u062a\u0646\u0627\u0632\u0644\u064a",//No i18n
"crm.column.unsort" : "\u0639\u062f\u0645 \u0627\u0644\u0641\u0631\u0632",//No I18n
"custmr.prtl.notes.shr.to.custmr": "\u0627\u0644\u0645\u0634\u0627\u0631\u0643\u0629 \u0645\u0639 \u0627\u0644\u0639\u0645\u064a\u0644", //NO I18N
"crm.label.edited":"\u062a\u0645 \u0627\u0644\u062a\u062d\u0631\u064a\u0631",//no i18n
"crm.label.edited.on":"\u062a\u0645 \u0627\u0644\u062a\u062d\u0631\u064a\u0631 \u0641\u064a",//no i18n
"crm.message.limit.exceed": "\u064a\u0633\u0645\u062d \u0628\u0640 {0} \u0623\u062d\u0631\u0641 \u0641\u0642\u0637 \u0644\u0640 {1}.", //NO I18N
"custmr.prtl.notes.shrd.with.custmr": "\u062a\u0645\u062a \u0627\u0644\u0645\u0634\u0627\u0631\u0643\u0629 \u0645\u0639 \u0627\u0644\u0639\u0645\u064a\u0644", //NO I18N
"crm.button.ok" : "\u0645\u0648\u0627\u0641\u0642", //NO I18N
"crm.role.already.selected" : "\u062a\u0645 \u062a\u062d\u062f\u064a\u062f \u0647\u0630\u0627 \u0627\u0644\u062f\u0648\u0631 \u0628\u0627\u0644\u0641\u0639\u0644", //no i18n
"crm.user.deleted": "\u062a\u0645 \u062d\u0630\u0641 \u0646\u062a\u0645 \u062d\u0630\u0641 \u0627\u0644\u0645\u0633\u062a\u062e\u062f\u0645",  //NO I18N
"crm.account.closed": "\u062a\u0645 \u0625\u063a\u0644\u0627\u0642 \u0647\u0630\u0627 \u0627\u0644\u062d\u0633\u0627\u0628S",  //NO I18N
"crm.start.chat": "\u0628\u062f\u0621 \u0645\u062d\u0627\u062f\u062b\u0629",  //NO I18N
"crm.start.call": "\u0628\u062f\u0621 \u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0629",  //NO I18N
"crm.start.video.call": "\u0628\u062f\u0621 \u0645\u0643\u0627\u0644\u0645\u0629 \u0641\u064a\u062f\u064a\u0648",  //NO I18N
"crm.recipient.invalid.email" : "\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0639\u0646\u0627\u0648\u064a\u0646 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u063a\u064a\u0631 \u0635\u0627\u0644\u062d\u0629.", //NO I18N
"crm.recipient.add.recipient" : "\u0625\u0636\u0627\u0641\u0629 \u0645\u0633\u062a\u0644\u0645 \u0625\u0636\u0627\u0641\u064a", //NO I18N

"Score Summary":"\u0645\u0644\u062e\u0635 \u0627\u0644\u0646\u0642\u0627\u0637",//No I18n
"Score":"\u0627\u0644\u0646\u062a\u064a\u062c\u0629",//No I18n
"Positive Score":"\u0646\u0642\u0637\u0629 \u0625\u064a\u062c\u0627\u0628\u064a\u0629",//No I18n
"Negative Score":"\u0646\u0642\u0637\u0629 \u0633\u0644\u0628\u064a\u0629",//No I18n
"Touch Point Score":"\u0646\u0642\u0637\u0629 \u062a\u0648\u0627\u0635\u0644",//No I18n
"Positive Touch Point Score":"\u0646\u0642\u0637\u0629 \u062a\u0648\u0627\u0635\u0644 \u0625\u064a\u062c\u0627\u0628\u064a\u0629",//No I18n
"Negative Touch Point Score":"\u0646\u0642\u0637\u0629 \u062a\u0648\u0627\u0635\u0644 \u0633\u0644\u0628\u064a\u0629",//No I18n
"crm.label.scoring.rules":"\u0642\u0648\u0627\u0639\u062f \u0648\u0636\u0639 \u0627\u0644\u062f\u0631\u062c\u0627\u062a",//No I18n
"crm.label.type.minutes": "\u0627\u0643\u062a\u0628 \u0647\u0646\u0627 \u0641\u064a \u062f\u0642\u0627\u0626\u0642", //NO I18N
"is\ OPEN":"\u0645\u0641\u062a\u0648\u062d\u0629",//no i18n
"is\ WON":"\u0645\u062d\u0631\u0632\u0629",//no i18n
"is\ LOST":"\u0645\u0641\u0642\u0648\u062f\u0629",//no i18n
"crm.potential.all.open":"\u0643\u0644 \u0627\u0644\u0645\u0631\u0627\u062d\u0644 \u0627\u0644\u0645\u0641\u062a\u0648\u062d\u0629",//no i18n
"crm.potential.all.won":"\u0643\u0644 \u0627\u0644\u0645\u0631\u0627\u062d\u0644 \u0645\u062d\u0631\u0632\u0629 \u0627\u0644\u0645\u063a\u0644\u0642\u0629",//no i18n
"crm.potential.all.lost":"\u0643\u0644 \u0627\u0644\u0645\u0631\u0627\u062d\u0644 \u0627\u0644\u0645\u0641\u0642\u0648\u062f\u0629 \u0627\u0644\u0645\u063a\u0644\u0642\u0629",//no i18n

"crm.campaign.member.status" : "\u062d\u0627\u0644\u0629 \u0627\u0644\u0639\u0636\u0648",//no i18n
"crm.dashboard.select.type" : "\u062a\u062d\u062f\u064a\u062f {0}",//no i18n
"crm.campaign.service.status":"\u062d\u0627\u0644\u0629 \u0627\u0644\u062e\u062f\u0645\u0629",//no i18n

"crm.label.addColumn":"\u0625\u0636\u0627\u0641\u0629 \u0639\u0645\u0648\u062f",//no i18n
"crm.button.clear.filter":"\u063a\u0644\u0642 \u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629",//no i18n
"crm.button.show.filter":"\u0625\u0638\u0647\u0627\u0631 \u0639\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629",//no i18n
"crm.las.error.user.maxlimit":"\u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f 20 \u0645\u0633\u062a\u062e\u062f\u0645\u064b\u0627 \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",//no i18n
"crm.las.error.picklist.maxlimit":"\u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f 20 \u0627\u062e\u062a\u064a\u0627\u0631\u064b\u0627 \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",//no i18n

"crm.fileuploader.message.responseerror": "\u0641\u0634\u0644 \u0627\u0644\u062a\u062d\u0645\u064a\u0644", //NO i18n
"crm.storage.create.error": "\u0644\u0627 \u064a\u0645\u0643\u0646 \u0625\u0646\u0634\u0627\u0621 \u0633\u062c\u0644\u0627\u062a \u062c\u062f\u064a\u062f\u0629 \u0644\u0623\u0646\u0643 \u0648\u0635\u0644\u062a \u0625\u0644\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0623\u0642\u0635\u0649 \u0645\u0646 \u062a\u062e\u0632\u064a\u0646 \u0627\u0644\u0628\u064a\u0627\u0646\u0627\u062a.", //NO I18N
"crm.storage.create.error.client": "\u0644\u0627 \u064a\u0645\u0643\u0646 \u0625\u0646\u0634\u0627\u0621 \u0633\u062c\u0644\u0627\u062a \u062c\u062f\u064a\u062f\u0629 \u0644\u0623\u0646 \u0627\u0644\u0645\u0633\u0624\u0648\u0644 \u0627\u0644\u062e\u0627\u0635 \u0628\u0643 \u0648\u0635\u0644 \u0625\u0644\u0649 \u0627\u0644\u062d\u062f \u0627\u0644\u0623\u0642\u0635\u0649 \u0645\u0646 \u0627\u0644\u062a\u062e\u0632\u064a\u0646 \u0644\u062f\u064a\u0647. \u0627\u062a\u0635\u0644 \u0628\u0640 {0} \u0644\u062d\u0644 \u0647\u0630\u0647 \u0627\u0644\u0645\u0634\u0643\u0644\u0629.", //NO I18N
"crm.storage.avail.info": "({0} \u0645\u062a\u0628\u0642\u064a \u0645\u0646 {1})", //NO I18N
"crm.storage.error.key.manage": "\u0625\u062f\u0627\u0631\u0629 \u062a\u062e\u0632\u064a\u0646 \u0627\u0644\u0628\u064a\u0627\u0646\u0627\u062a \u0627\u0644\u062e\u0627\u0635 \u0628\u0643", //NO I18N
"Records": "\u0627\u0644\u0633\u062c\u0644\u0627\u062a", //NO I18N
"crm.zcampaign.test.email.input.placeholder" : "\u0625\u062f\u062e\u0627\u0644 \u0639\u0646\u0648\u0627\u0646 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a", //NO I18N
"crm.workflow.alert.additional.recipients" : "\u0645\u0633\u062a\u0644\u0645\u0648\u0646 \u0625\u0636\u0627\u0641\u064a\u0648\u0646", //NO I18N
"crm.workflow.alert.type.otherEmails" : "\u0627\u0633\u062a\u062e\u062f\u0645 \u0627\u0644\u0641\u0648\u0627\u0635\u0644 \u0644\u0644\u0641\u0635\u0644 \u0628\u064a\u0646 \u0639\u0646\u0627\u0648\u064a\u0646 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0627\u0644\u0625\u0636\u0627\u0641\u064a\u0629.", //NO I18N
"crm.related.contact.account" : "{0} \u0627\u0644\u0645\u0631\u062a\u0628\u0637 \u0628\u0640 {1}",//No I18n
	"crm.allcontact.show" : "\u0643\u0627\u0641\u0629 {0}",//No I18n
	"crm.button.mass.show" : "\u0625\u0638\u0647\u0627\u0631",//No I18n
"crm.msg.custom.view.not.replied": "\u0631\u0633\u0627\u0626\u0644 \u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0631\u062f \u0639\u0644\u064a\u0647\u0627", //NO I18N
"crm.msg.custom.view.replied": "\u0631\u0633\u0627\u0626\u0644 \u062a\u0645 \u0627\u0644\u0631\u062f \u0639\u0644\u064a\u0647\u0627", //NO I18N
"crm.workflow.select.recipients" : "\u0627\u0644\u0645\u0633\u062a\u0644\u0645\u0648\u0646", //NO I18N
"crm.custom.module.no.profile.selected.alert1":"\u064a\u064f\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062f \u0645\u0644\u0641 \u062a\u0639\u0631\u064a\u0641\u064a \u0648\u0627\u062d\u062f \u0639\u0644\u0649 \u0627\u0644\u0623\u0642\u0644.",//NO I18N
"crm.auto.enrich.remove.default.profile" : "\u0639\u0630\u0631\u064b\u0627\u060c \u0644\u0643\u0646 \u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u0625\u0632\u0627\u0644\u0629 \u0627\u0644\u0645\u0644\u0641 \u0627\u0644\u062a\u0639\u0631\u064a\u0641\u064a \u0627\u0644\u0627\u0641\u062a\u0631\u0627\u0636\u064a.",//NO I18N
"crm.inv.label.add.emails" : "\u0625\u0636\u0627\u0641\u0629 \u0639\u0646\u0627\u0648\u064a\u0646 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a" ,//NO I18N
"crm.prediction.analytics.filter.year":"\u0627\u0644\u0639\u0627\u0645 \u0627\u0644\u0645\u0627\u0636\u064a",//no i18n
"Previous\ FY":"\u0627\u0644\u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u0633\u0627\u0628\u0642\u0629",//no i18n
"Current\ FY":"\u0627\u0644\u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u062d\u0627\u0644\u064a\u0629",//no i18n
"Next\ FY":"\u0627\u0644\u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u0642\u0627\u062f\u0645\u0629",//no i18n
"Current\ FQ":"\u0627\u0644\u0631\u0628\u0639 \u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u062d\u0627\u0644\u064a\u0629",//no i18n
"Next\ FQ":"\u0627\u0644\u0631\u0628\u0639 \u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u0642\u0627\u062f\u0645\u0629",//no i18n
"Previous\ FQ":"\u0627\u0644\u0631\u0628\u0639 \u0633\u0646\u0629 \u0627\u0644\u0645\u0627\u0644\u064a\u0629 \u0627\u0644\u0633\u0627\u0628\u0642\u0629",//no i18n
"crm.inv.label.add.emails" : "\u0625\u0636\u0627\u0641\u0629 \u0639\u0646\u0627\u0648\u064a\u0646 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a", //NO I18N
"crm.picklist.sample.text":"\u0646\u0635 \u0627\u0644\u0639\u064a\u0646\u0629",//no i18n
"crm.more.colors":"\u0627\u0644\u0645\u0632\u064a\u062f \u0645\u0646 \u0627\u0644\u0623\u0644\u0648\u0627\u0646",//no i18n
"crm.button.back.alone":"\u0631\u062c\u0648\u0639",//no i18n
"crm.field.label.email":"\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",//no i18n
"crm.zia.nba.feature.label":"\u062b\u0627\u0646\u064a \u0623\u0641\u0636\u0644 \u0625\u062c\u0631\u0627\u0621",//no i18n
"Meeting":"\u0627\u0644\u0627\u062c\u062a\u0645\u0627\u0639",//no i18n
"crm.gdpr.notavailable.field":"\u063a\u064a\u0631 \u0645\u062a\u0648\u0641\u0631",//no i18n
	"crm.setup.system.ziasimilarity":"\u062a\u0648\u0635\u064a\u0629 \u0627\u0644\u062a\u0634\u0627\u0628\u0647",//no i18n
	"crm.gdpr.notavailable.field":"\u063a\u064a\u0631 \u0645\u062a\u0648\u0641\u0631",//no i18n
	"crm.label.simply.in":"\u0628\u0648\u0635\u0629",//no i18n
	"crm.filter.label.all.products":"\u0643\u0627\u0641\u0629 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "\u0627\u0644\u062c\u0632\u0621", // NO I18N
	"abm.segment.names": "\u0623\u0633\u0645\u0627\u0621 \u0627\u0644\u0623\u062c\u0632\u0627\u0621", // NO I18N
	"abm.abm.segment.name": "\u0627\u0633\u0645 \u062c\u0632\u0621 ABM", // NO I18N
	"abm.segmentation.techniques": "\u0623\u0633\u0627\u0644\u064a\u0628 \u062a\u062c\u0632\u0626\u0629 ABM", // NO I18N
	"abm.filters": "\u0639\u0648\u0627\u0645\u0644 \u062a\u0635\u0641\u064a\u0629 ABM", // NO I18N
	"abm.filters.info": "\u0633\u062a\u0638\u0647\u0631 \u0639\u0648\u0627\u0645\u0644 \u0627\u0644\u062a\u0635\u0641\u064a\u0629 \u0647\u0630\u0647 \u0627\u0644\u0645\u0639\u0644\u0648\u0645\u0627\u062a \u0628\u0646\u0627\u0621\u064b \u0639\u0644\u0649 {0} \u0627\u0644\u0645\u0642\u062a\u0631\u0646 \u0628\u0640 {1}.", // NO I18N
	"abm.rfm.label": "\u062a\u0633\u0645\u064a\u0629 RFM", // NO I18N
	"abm.firmographics.label": "\u062a\u0633\u0645\u064a\u0629 \u062f\u064a\u0645\u0648\u063a\u0631\u0627\u0641\u064a\u0629 \u0627\u0644\u0634\u0631\u0643\u0629" ,// NO I18N
	"abm.recommendation.label": "\u062a\u0633\u0645\u064a\u0629 \u0627\u0644\u062a\u0648\u0635\u064a\u0629", // NO I18N
	"abm.voc.label": "\u062a\u0633\u0645\u064a\u0629 VOC", // NO I18N
	"abm.engagement.label": "\u062a\u0633\u0645\u064a\u0629 \u0627\u0644\u062a\u0641\u0627\u0639\u0644", // NO I18N
	"abm.overall.score": "\u0627\u0644\u062a\u0642\u064a\u064a\u0645 \u0627\u0644\u0625\u062c\u0645\u0627\u0644\u064a", // NO I18N
	"abm.mood.score": "\u062a\u0642\u064a\u064a\u0645 \u0627\u0644\u062d\u0627\u0644\u0629", // NO I18N
	"abm.value.score": "\u062a\u0642\u064a\u064a\u0645 \u0627\u0644\u0642\u064a\u0645\u0629", // NO I18N
	"abm.engagement.score": "\u062a\u0642\u064a\u064a\u0645 \u0627\u0644\u062a\u0641\u0627\u0639\u0644", // NO I18N
	"abm.rfm.score": "\u062a\u0642\u064a\u064a\u0645 RFM", // NO I18N
	"crm.column.list.max.group.limit" : "\u062a\u0645 \u0627\u0644\u0648\u0635\u0648\u0644 \u0625\u0644\u0649 \u0623\u0642\u0635\u0649 \u062d\u062f \u0645\u0633\u0645\u0648\u062d \u0628\u0647.", //no i18n
	"crm.select":"\u062a\u062d\u062f\u064a\u062f",
	"crm.customview.pin.column":"\u062a\u062b\u0628\u064a\u062a \u0639\u0645\u0648\u062f",//no i18n
	"crm.customview.unpin.column":"\u0625\u0644\u063a\u0627\u0621 \u062a\u062b\u0628\u064a\u062a \u0639\u0645\u0648\u062f",//no i18n
	"crux.custom.field.greater.than.equalto":"يجب أن يكون {0} أكبر من أو يساوي {1}.",
"crux.users.selected.plural" : "تم تحديد {0} من المستخدمين.",
"crux.user.selected.singular" :"تم تحديد {0} مستخدمًا.",
"crux.criteria.empty.secondaryfield.module" : "لم يتم العثور على حقل {0} مطابق في الوحدة النمطية {1}",
"crux.criteria.empty.secondaryfield" : "لا يوجد حقل {0} آخر متاح للمقارنة، يُرجى إدخال قيمة للمقارنة.",
"crux.logged.in.role.definition" : "دور المستخدم الذي يبدأ إجراءات السجل",
"zia.similarity.smartfilter.score":"\u062f\u0631\u062c\u0629 \u0627\u0644\u062a\u0634\u0627\u0628\u0647",//no i18n
	"zia.similarity.smartfilter.records":"{0} \u0645\u0634\u0627\u0628\u0647 \u0644\u0640",//no i18n
	"zia.similarity.smartfilter.records.search":"\u0625\u0638\u0647\u0627\u0631 {0} \u0645\u0634\u0627\u0628\u0647 \u0644\u0640",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0623\u0643\u062b\u0631 \u0645\u0646 {0} {1}",//no i18n
	"crm.select":"\u062a\u062d\u062f\u064a\u062f",
	"crux.custom.field.greater.than.equalto":"يجب أن يكون {0} أكبر من أو يساوي {1}.",
"crux.users.selected.plural" : "تم تحديد {0} من المستخدمين.",
"crux.user.selected.singular" :"تم تحديد {0} مستخدمًا.",
"crux.criteria.empty.secondaryfield.module" : "لم يتم العثور على حقل {0} مطابق في الوحدة النمطية {1}",
"crux.criteria.empty.secondaryfield" : "لا يوجد حقل {0} آخر متاح للمقارنة، يُرجى إدخال قيمة للمقارنة.",
"crux.logged.in.role.definition" : "دور المستخدم الذي يبدأ إجراءات السجل",
	"crux.max.limit.unselect" : "لا يمكنك إلغاء تحديد أكثر من {0} {1}.", // NO I18N
	"crux.existing.tag" : "تم تحديد \u201d{0}\u201c بالفعل", // NO I18N
"crm.record.locked":"\u0645\u0642\u0641\u0644",//no i18n
"crm.label.true":"\u0635\u062d\u064a\u062d",//no i18n
"crm.label.false" : "False",//NO I18N
"crm.filter.number.range":"\u0627\u0644\u0642\u064a\u0645 \u0645\u0646 {0}-{1}",//no i18n
"crm.smartfilter.picklist.options.msg":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0647\u0630\u0627 \u0627\u0644\u062e\u064a\u0627\u0631 \u0644\u0623\u0643\u062b\u0631 \u0645\u0645\u0646 10 \u0642\u064a\u0645 \u0645\u062d\u062f\u062f\u0629.",//no i18n
"crm.chosen.maximum.campaigns.selected":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0623\u0643\u062b\u0631 \u0645\u0646 {0}",//no i18n
"crux.smartfilter.multiselect.maximum.selected":"\u0644\u0627 \u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u062f\u064a\u062f \u0623\u0643\u062b\u0631 \u0645\u0646 {0} {1}",//no i18n
"crm.best.time.column.label":"\u0623\u0641\u0636\u0644 \u0648\u0642\u062a \u0644\u0644\u0627\u062a\u0635\u0627\u0644",//no i18n
"crm.competitoralert.mentioned.in.email":"\u062a\u0645\u062a \u0627\u0644\u0625\u0634\u0627\u0631\u0629 \u0625\u0644\u064a\u0647 \u0641\u064a <b>\u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a</b>",//no i18n
"crm.competitoralert.with.sentiment":"\u0645\u0639 \u062a\u0648\u062c\u0647",//no i18n
"crm.competitoralert.name.error":"\u064a\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 \u0642\u064a\u0645\u0629 \u0635\u0627\u0644\u062d\u0629 \u0644\u0627\u0633\u0645 \u0627\u0644\u0645\u0646\u0627\u0641\u0633.",//no i18n
"crm.competitoralert.date.error":"\u064a\u0631\u062c\u0649 \u0625\u062f\u062e\u0627\u0644 \u062a\u0627\u0631\u064a\u062e \u0635\u0627\u0644\u062d \u0644\u0644\u0625\u0634\u0627\u0631\u0629 \u0644\u0644\u0645\u0646\u0627\u0641\u0633 \u0641\u064a \u0627\u0644\u0631\u0633\u0627\u0644\u0629.",//no i18n
"crm.competitoralert.sentiment.error":"\u064a\u0631\u062c\u0649 \u062a\u062d\u062f\u064a\u062f \u062e\u064a\u0627\u0631 \u0635\u0627\u0644\u062d \u0644\u062a\u0648\u062c\u0647 \u0627\u0644\u0645\u0646\u0627\u0641\u0633.",//no i18n
"crm.competitor.name":"\u0627\u0644\u0645\u0646\u0627\u0641\u0633",
"crm.lookup.advance.error.msg" : "\u062d\u062f\u062b \u062e\u0637\u0623 \u0623\u062b\u0646\u0627\u0621 \u062a\u0637\u0628\u064a\u0642 \u0639\u0646\u0635\u0631 \u0627\u0644\u062a\u0635\u0641\u064a\u0629. \u0623\u0639\u062f \u062a\u062d\u062f\u064a\u062b \u0627\u0644\u0635\u0641\u062d\u0629 \u0648\u0623\u0639\u062f \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629.",
"crm.label.add.tags":"\u0625\u0636\u0627\u0641\u0629 \u0639\u0644\u0627\u0645\u0627\u062a",//no i18n
"crm.confirm.deassociate":"\u0625\u0644\u063a\u0627\u0621 \u0627\u0644\u062a\u0639\u064a\u0646",//no i18n
"Contact\ Name" : "\u0627\u0633\u0645 {0}",
	"Lead\ Name" : "\u0627\u0633\u0645 {0}",
	"-None-" : "\u0628\u0644\u0627",
	"January":"\u064a\u0646\u0627\u064a\u0631",
	"February":"\u0641\u0628\u0631\u0627\u064a\u0631",
	"March":"\u0645\u0627\u0631\u0633",
	"April":"\u0623\u0628\u0631\u064a\u0644",
	"May":"\u0645\u0627\u064a\u0648",
	"June":"\u064a\u0648\u0646\u064a\u0648",
	"July":"\u064a\u0648\u0644\u064a\u0648",
	"August":"\u0623\u063a\u0633\u0637\u0633",
	"September":"\u0633\u0628\u062a\u0645\u0628\u0631",
	"October":"\u0623\u0643\u062a\u0648\u0628\u0631",
	"November":"\u0646\u0648\u0641\u0645\u0628\u0631",
	"December":"\u062f\u064a\u0633\u0645\u0628\u0631",

	// Image Upload literals
	"crm.image.empty.name.placeholder" : "\u0623\u062f\u062e\u0644 \u0627\u0633\u0645\u064b\u0627",
	"crm.image.crop.and.rotate" : "\u0627\u0644\u0627\u0642\u062a\u0635\u0627\u0635 \u0648\u0627\u0644\u062a\u062f\u0648\u064a\u0631",
	"crm.view.attachment.download" : "\u062a\u0646\u0632\u064a\u0644",
	"crm.label.edit" : "\u062a\u062d\u0631\u064a\u0631",
	"crm.label.close" : "\u0625\u063a\u0644\u0627\u0642",
	"crm.label.any" : "\u0623\u064a",
	"crm.image.description" : "\u0625\u0636\u0627\u0641\u0629 \u0648\u0635\u0641...",
	"crm.image.zoom.in" : "\u062a\u0643\u0628\u064a\u0631",
	"crm.image.zoom.out" : "\u062a\u0635\u063a\u064a\u0631",
	"crm.image.actual.size" : "\u0627\u0644\u062d\u062c\u0645 \u0627\u0644\u0641\u0639\u0644\u064a",
	"crm.image.reset" : "\u0625\u0639\u0627\u062f\u0629 \u062a\u0639\u064a\u064a\u0646",
	"crm.label.lowercase.of" : "\u0645\u0646",
	"crm.image.width.px" : "\u0628\u0643\u0633\u0644",
	"crm.image.desc.maxsize " : "\u064a\u062c\u0628 \u0623\u0644\u0627 \u064a\u062a\u062c\u0627\u0648\u0632 \u0627\u0644\u0648\u0635\u0641 255 \u062d\u0631\u0641.",
	"crm.image.name.maxsize" : "\u064a\u062c\u0628 \u0623\u0644\u0627 \u064a\u062a\u062c\u0627\u0648\u0632 \u0627\u0644\u0627\u0633\u0645 100 \u062d\u0631\u0641.",
	"crm.label.readonly" : "\u0642\u0631\u0627\u0621\u0629 \u0641\u0642\u0637",
	"crm.fileuploader.message.totalfilesizeexceeded":"\u062a\u062c\u0627\u0648\u0632 \u062d\u062c\u0645 \u0627\u0644\u0645\u0644\u0641 \u0627\u0644\u0643\u0644\u064a \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0628\u0647 \u0648\u0647\u0648 {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u064a\u0645\u0643\u0646\u0643 \u0631\u0641\u0639 {0} \u0645\u0644\u0641\u0627\u062a \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u064a\u0645\u0643\u0646\u0643 \u0625\u0631\u0641\u0627\u0642 {0} \u0645\u0644\u0641\u0627\u062a \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",//no i18n
	"crm.ImageuploadField.size.limit":"\u062a\u062c\u0627\u0648\u0632 \u062d\u062c\u0645 \u0627\u0644\u0645\u0644\u0641 \u0627\u0644\u0643\u0644\u064a \u0644\u0644\u0635\u0648\u0631 \u0627\u0644\u062d\u062f \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0628\u0647 \u0648\u0647\u0648 {0} \u0645\u064a\u063a\u0627\u0628\u0627\u064a\u062a.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u064a\u0645\u0643\u0646\u0643 \u0627\u062e\u062a\u064a\u0627\u0631 \u0635\u0648\u0631\u0629 \u0648\u0627\u062d\u062f\u0629 \u0641\u0642\u0637",//no i18n
	"crm.image.crop.error":"\u064a\u062a\u0639\u0630\u0631 \u0642\u0635 \u0627\u0644\u0635\u0648\u0631\u0629\u060c \u064a\u0631\u062c\u0649 \u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629 \u0644\u0627\u062d\u0642\u064b\u0627!",//no i18n
	"crm.attach.here":"\u0647\u0646\u0627.",//no i18n
	"crm.label.browse.files":"\u062a\u0635\u0641\u062d \u0627\u0644\u0645\u0644\u0641\u0627\u062a",//no i18n
	"crm.label.lowercase.or":"\u0623\u0648",//no i18n
	"crm.image.header":"\u0627\u0644\u0635\u0648\u0631\u0629",//no i18n
	"crm.image.n.n":"\u0646\u0633\u0628\u0629 N:N",//no i18n
	"crm.image.2.2":"\u0646\u0633\u0628\u0629 2:2",//no i18n
	"crm.image.4.3":"\u0646\u0633\u0628\u0629 4:3",//no i18n
	"crm.image.16.9":"\u0646\u0633\u0628\u0629 16:9",//no i18n
	"crm.image.height.width.swap":"\u062a\u0628\u062f\u064a\u0644 \u0627\u0644\u0627\u0631\u062a\u0641\u0627\u0639 \u0648\u0627\u0644\u0639\u0631\u0636",//no i18n
	"crm.image.rotate.image":"\u062a\u062f\u0648\u064a\u0631",//no i18n
	"crm.label.lowercase.of":"\u0645\u0646",//no i18n
	"crm.image.unsupported.corrupted.single":"\u0627\u0644\u0635\u0648\u0631\u0629 \u0627\u0644\u062a\u064a \u062a\u062d\u0627\u0648\u0644 \u062a\u062d\u0645\u064a\u0644\u0647\u0627 \u062a\u0627\u0644\u0641\u0629.",//no i18n
	"Browse":"\u0627\u0633\u062a\u0639\u0631\u0627\u0636",//no i18n
	"zc.editor.width":"\u0627\u0644\u0639\u0631\u0636",//no i18n
	"ze.editor.height":"\u0627\u0644\u0627\u0631\u062a\u0641\u0627\u0639",//no i18n
	"crm.label.delete":"\u062d\u0630\u0641",//no i18n
	"crm.image.error.gif":"\u0644\u0627 \u064a\u0645\u0643\u0646 \u0627\u0642\u062a\u0635\u0627\u0635 \u0635\u0648\u0631\u0629 GIF.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} \u0645\u0644\u0641\u0627\u062a \u0623\u062e\u0631\u0649",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u0627\u0644\u0645\u0644\u0641 {0} \u063a\u064a\u0631 \u0645\u062f\u0639\u0648\u0645.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u0627\u0644\u0645\u0644\u0641\u0627\u0646 {0} \u0648{1} \u063a\u064a\u0631 \u0645\u062f\u0639\u0648\u0645\u064a\u0646.",//no i18n
	"crm.imageupload.failure":"\u062a\u0639\u0630\u0631 \u062a\u062d\u0645\u064a\u0644 \u0628\u0639\u0636 \u0627\u0644\u0635\u0648\u0631. \u064a\u064f\u0631\u062c\u0649 \u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u0645\u062d\u0627\u0648\u0644\u0629 \u0644\u0627\u062d\u0642\u064b\u0627.",//no i18n
	"crm.attach.upload.sizelimit":"\u0625\u062c\u0645\u0627\u0644\u064a \u062d\u062f \u0627\u0644\u062d\u062c\u0645",//no i18n
	


	"crm.fileuploader.message.maxfilesexceeded" : "\u064a\u0645\u0643\u0646\u0643 \u0631\u0641\u0639 {0} \u0645\u0644\u0641\u0627\u062a \u0641\u0642\u0637 \u0643\u062d\u062f \u0623\u0642\u0635\u0649.",
	"crm.file.upload.maxlength.exceeds" : "\u064a\u0645\u0643\u0646\u0643 \u062a\u062d\u0645\u064a\u0644 \u0645\u0644\u0641 \u0648\u0627\u062d\u062f \u0641\u0642\u0637 \u0641\u064a {0}."
	
}
